import { useMemo, useRef } from 'react';

import { useNavigate } from 'react-router';

import { useNotification } from 'src/hooks/useNotification';
import { EditorialAnalyticsApi } from 'src/api/requests/analytics';
import { UsersApi } from 'src/api/requests/users';
import { AuthApi } from 'src/api/requests/auth';
import { SystemSettingsApi } from 'src/api/requests/system-settings';
import { CountriesApi } from 'src/api/requests/countries';
import { RESPONSE_STATUSES, DEFAULT_ERROR_MESSAGE } from 'src/api/constants';

const SERVER_IDLE_TIMEOUT = 20000;
const TIMEOUT_ERROR_MESSAGE =
    'Server is idle... Please wait a minute for page to load (or refresh the page if error occurs)';

/**
 * Needed to show only one timeout error
 */
let timeoutErrorShown = false;

export const useApi = () => {
    const notifier = useNotification();
    const navigate = useRef(useNavigate());
    return useMemo(() => {
        /**
         *
         * @type {import('smg-iq').CreateApiCall}
         */
        const createApiCall =
            (apiCallFunc) =>
            async (...args) => {
                let timeoutTimer;
                try {
                    if (navigator.onLine && !timeoutErrorShown) {
                        timeoutTimer = setTimeout(() => {
                            if (!timeoutErrorShown) {
                                timeoutErrorShown = true;
                                notifier('info', TIMEOUT_ERROR_MESSAGE);
                            }
                        }, SERVER_IDLE_TIMEOUT);
                    }

                    const res = await apiCallFunc(...args);

                    if (res.status === RESPONSE_STATUSES.transport_error) {
                        notifier('error', 'Network connection issue');
                        return null;
                    }

                    if (res.status === RESPONSE_STATUSES.unathorized) {
                        notifier('error', 'Unathorized');
                        navigate.current('/login', { replace: false });
                        return null;
                    }

                    if (res.status === RESPONSE_STATUSES.forbidden) {
                        notifier(
                            'error',
                            "Forbidden. You don't have enough permissions to view this page"
                        );
                        return null;
                    }

                    if (!res.success) {
                        notifier('error', res.error || DEFAULT_ERROR_MESSAGE);
                    }

                    return res.data;
                } catch (e) {
                    console.error(e);
                    notifier('error', DEFAULT_ERROR_MESSAGE);
                    return null;
                } finally {
                    clearTimeout(timeoutTimer);
                    if (timeoutTimer && timeoutErrorShown) {
                        timeoutErrorShown = false;
                    }
                }
            };
        /**
         *
         * @type {import('smg-iq').CreateApiSection}
         */
        const createApiSection = (section) =>
            Object.keys(section).reduce((acc, sectionKey) => {
                acc[sectionKey] = createApiCall(section[sectionKey]);
                return acc;
            }, {});

        return {
            EditorialAnalytics: createApiSection(EditorialAnalyticsApi),
            Users: createApiSection(UsersApi),
            Auth: createApiSection(AuthApi),
            SystemSettings: createApiSection(SystemSettingsApi),
            Countries: createApiSection(CountriesApi)
        };
    }, [notifier, navigate]);
};
