/**
 *
 * @param {import('smg-iq').ReportColumnWithGroup} column
 * @param {import('smg-iq').AggregateWidget} widget
 * @param {number} [sortOrder]
 * @param {boolean} [hideFromTable]
 * @returns {import('smg-iq').ColumnDefinition}
 */
export const withWidget = (column, widget, sortOrder, hideFromTable) => {
    const copy = structuredClone(column);
    copy.column.aggregateWidget = structuredClone(widget);
    if (typeof sortOrder === 'number') {
        copy.column.aggregateWidget.sortOrder = sortOrder;
    }

    if (hideFromTable) {
        copy.column.hideFromTable = true;
    }
    return copy;
};
