/**
 *
 * @type {import('../index.d').CreateTransaction}
 */
export const createTransaction = (obj = {}) => {
    let diff = {};
    const source = obj;
    let deletedFields = [];

    return {
        state: new Proxy(source, {
            get: (target, prop) => {
                if (prop in diff) return diff[prop];
                if (deletedFields.indexOf(prop) !== -1) return undefined;
                return source[prop];
            },
            set: (target, prop, value) => {
                diff[prop] = value;
                if (prop in source && diff[prop] === source[prop]) {
                    delete diff[prop];
                }
                deletedFields = deletedFields.filter((f) => f !== prop);
                return true;
            },
            deleteProperty: (target, prop) => {
                if (prop in diff) {
                    delete diff[prop];
                }

                if (prop in source) {
                    deletedFields.push(prop);
                }
                return true;
            }
        }),
        get deletedFields() {
            return deletedFields;
        },
        commit: () => {
            Object.keys(diff).forEach((k) => {
                source[k] = diff[k];
            });
            deletedFields.forEach((f) => delete source[f]);
            deletedFields = [];
            diff = {};
        },
        rollback: () => {
            deletedFields = [];
            diff = {};
        },
        get diff() {
            return diff;
        },
        get isDirty() {
            return Object.keys(diff).length > 0 || deletedFields.length > 0;
        }
    };
};
