import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 * @param {{[k: string]: string}} settings
 * @returns {Promise<import('smg-iq').ApiCallResult<Array<import('smg-iq').SystemSettingGroup>>>}
 */
export const saveSettings = (settings) =>
    asyncFetch('system-settings/save', requestConfig(settings));
