import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = {
    method: 'GET'
};

/**
 * @returns {Promise<import('smg-iq').ApiCallResult<Array<import('smg-iq').SystemSettingGroup>>>}
 */
export const getAll = () => asyncFetch('system-settings/all', requestConfig);
