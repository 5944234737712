import {
    addDays,
    addMonths,
    addQuarters,
    addYears,
    ensureIsDate,
    formatISODate,
    getFormatDateUniversal,
    getWeekFirstDay,
    getMonthFirstDay,
    getQuarterFirstDay,
    getQuarterLastDay
} from 'smg-iq-utils';

/**
 *
 * @param {Date} date
 * @returns  {Date}
 */
const ensureNotInFuture = (date) => {
    const now = new Date();
    const today = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );
    return date > today ? today : date;
};

export const predefinedDateIntervals = [
    {
        keyPrefix: 'this-week',
        supportsDecrement: true,
        minDecrement: 0,
        getName: (decrement = 0) =>
            decrement === 0 ? 'This Week' : `Last ${decrement + 1} Weeks`,
        toDateInterval: (decrement) => ({
            from: addDays(getWeekFirstDay(new Date()), -7 * decrement),
            to: ensureNotInFuture(new Date())
        })
    },
    {
        keyPrefix: 'this-month',
        supportsDecrement: false,
        minDecrement: 0,
        getName: (decrement = 0) =>
            decrement === 0
                ? 'This Month'
                : `Last ${decrement + 1} Months including this Month`,
        toDateInterval: (decrement) => ({
            from: addMonths(getMonthFirstDay(new Date()), -decrement),
            to: ensureNotInFuture(new Date())
        })
    },
    {
        keyPrefix: 'last-month',
        supportsDecrement: true,
        minDecrement: 0,
        getName: (decrement = 0) =>
            decrement === 0 ? 'Last Month' : `Last ${decrement + 1} Months`,
        toDateInterval: (decrement) => ({
            from: addMonths(
                getMonthFirstDay(addDays(getMonthFirstDay(new Date()), -1)),
                -decrement
            ),
            to: ensureNotInFuture(addDays(getMonthFirstDay(new Date()), -1))
        })
    },
    {
        keyPrefix: 'this-quarter',
        supportsDecrement: false,
        minDecrement: 0,
        getName: (decrement = 0) =>
            decrement === 0
                ? 'This Quarter'
                : `Last ${decrement + 1} Quarters including this Quarter`,
        toDateInterval: (decrement) => ({
            from: addQuarters(getQuarterFirstDay(new Date()), -decrement),
            to: ensureNotInFuture(getQuarterLastDay(new Date()))
        })
    },
    {
        keyPrefix: 'last-quarter',
        supportsDecrement: true,
        minDecrement: 0,
        getName: (decrement = 0) =>
            decrement === 0 ? 'Last Quarter' : `Last ${decrement + 1} Quarters`,
        toDateInterval: (decrement) => ({
            from: addQuarters(
                getQuarterFirstDay(addDays(getQuarterFirstDay(new Date()), -1)),
                -decrement
            ),
            to: ensureNotInFuture(addDays(getQuarterFirstDay(new Date()), -1))
        })
    },
    {
        keyPrefix: 'this-year',
        supportsDecrement: false,
        minDecrement: 0,
        getName: (decrement = 0) =>
            decrement === 0
                ? 'This Year'
                : `Last ${decrement + 1} Years including this year`,
        toDateInterval: (decrement) => ({
            from: addYears(
                new Date(
                    Date.UTC(new Date().getUTCFullYear(), 0, 1, 0, 0, 0, 0)
                ),
                -decrement
            ),
            to: ensureNotInFuture(
                new Date(
                    Date.UTC(new Date().getUTCFullYear(), 11, 31, 0, 0, 0, 0)
                )
            )
        })
    },
    {
        keyPrefix: 'last-year',
        supportsDecrement: true,
        minDecrement: 0,
        getName: (decrement = 0) =>
            decrement === 0 ? 'Last Year' : `Last ${decrement + 1} Years`,
        toDateInterval: (decrement) => ({
            from: addYears(
                new Date(
                    Date.UTC(new Date().getUTCFullYear() - 1, 0, 1, 0, 0, 0, 0)
                ),
                -decrement
            ),
            to: new Date(
                Date.UTC(new Date().getUTCFullYear() - 1, 11, 31, 0, 0, 0, 0)
            )
        })
    }
];

/**
 *
 * @param {typeof predefinedDateIntervals} intervals
 * @param {string} value
 * @returns {{keyPrefix: string; decrement: number;}}
 */
export const getIntervalAndDecrement = (intervals, value) => {
    const empty = { keyPrefix: null, decrement: null };
    if (typeof value !== 'string') return empty;
    const regex = /^(.+?)(~(\d+))?$/;
    const [, type, , decrement] = regex.exec(value);
    const pInterval = intervals.find((pi) => pi.keyPrefix === type);
    if (!pInterval) return empty;
    return {
        keyPrefix: type,
        decrement: parseInt(decrement || 0, 10)
    };
};

/**
 *
 * @param {string} value
 * @param {typeof predefinedDateIntervals} additional
 * @returns
 */
export const parsePredefinedInterval = (value, additional = []) => {
    if (typeof value === 'string') {
        const regex = /^(.+?)(~(\d+))?$/;
        const [, type, , decrement] = regex.exec(value);
        const pInterval = [
            ...(additional || []),
            ...predefinedDateIntervals
        ].find((pi) => pi.keyPrefix === type);
        if (pInterval) {
            return pInterval.toDateInterval(
                decrement ? parseInt(decrement, 10) : 0
            );
        }

        throw new Error(`unknown predefined interval ${type}`);
    }
    return value;
};

const formatDateUniversal = getFormatDateUniversal('en-us');

const formatBareInterval = (from, to, separator = ' - ') => {
    from = ensureIsDate(from);
    to = ensureIsDate(to);

    return from.getTime() === to.getTime()
        ? formatDateUniversal(from)
        : `${formatDateUniversal(from)}${separator}${formatDateUniversal(to)}`;
};

/**
 * Formats `interval` to a human readable string.
 * e.g. last-month becomes something like `2022-01-01 - 2022-01-31`
 * @param {string | { from: Date; to: Date; }} interval
 * @param {typeof predefinedDateIntervals} additionalIntervals
 * @param {boolean} hideDatesForKnownRanges
 * @returns
 */
export const formatDateInterval = (
    interval,
    additionalIntervals = [],
    hideDatesForKnownRanges = false
) => {
    let parsed;
    let label = '';
    if (typeof interval === 'string') {
        const additional = additionalIntervals.find((r) =>
            interval.startsWith(r.keyPrefix)
        );
        let range;
        if (additional) {
            const { decrement, keyPrefix } = getIntervalAndDecrement(
                [additional],
                interval
            );
            if (decrement && keyPrefix) {
                range = additional.toDateInterval(decrement);
                label = additional.getName(decrement);
            } else {
                range = additional.toDateInterval();
                label = additional.getName();
            }
        } else {
            range = interval;
            const { decrement, keyPrefix } = getIntervalAndDecrement(
                predefinedDateIntervals,
                interval
            );
            const pInterval = predefinedDateIntervals.find(
                (pi) => pi.keyPrefix === keyPrefix
            );
            if (!pInterval) throw new Error('unknown date interval');
            label = decrement
                ? pInterval.getName(decrement)
                : pInterval.getName();
        }

        parsed = parsePredefinedInterval(range);
    } else {
        parsed = interval;
    }

    if (label) {
        return `${label}${
            hideDatesForKnownRanges
                ? ''
                : ` (${formatBareInterval(parsed.from, parsed.to)})`
        }`;
    }
    return formatBareInterval(parsed.from, parsed.to);
};

/**
 *
 * @param {{from: Date; to: Date;} | string} interval
 * @param {string?} [separator]
 * @returns
 */
export const stringifyInterval = (interval, separator = '+') => {
    if (typeof interval === 'string') return interval;
    return `${formatISODate(interval.from)}${separator}${formatISODate(
        interval.to
    )}`;
};
