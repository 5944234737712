/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorUsersAggregateWidget = {
    title: 'Users',
    hint: {
        avg: 'Average number of users per author content',
        max: 'Maximum number of users per author content',
        min: 'Minimum number of users per author content',
        default: 'Total number of users for all authors',
        median: 'Median number of users per author content'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorUsersPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Users / Article',
    hint: {
        default:
            'Average Users / Article for all authors in the selected period',
        max: 'Maximum Users / Article for all authors in the selected period',
        min: 'Minimum Users / Article for all authors in the selected period',
        median: 'Median Users / Article for all authors in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSessionsAggregateWidget = {
    title: 'Sessions',
    hint: {
        avg: 'Average number of sessions per author',
        max: 'Maximum number of sessions per author',
        min: 'Minimum number of sessions per author',
        default: 'Total number of sessions for all authors',
        median: 'Median number of sessions per author'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSessionsPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Sessions / Article',
    hint: {
        avg: 'Average per author number of sessions per article',
        max: 'Maximum per author number of sessions per article',
        min: 'Minimum per author number of sessions per article',
        default: 'Total per author number of sessions per article',
        median: 'Median per author number of sessions per article'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorPageViewsAggregateWidget = {
    title: 'Page Views',
    hint: {
        avg: 'Average number of page views per author',
        max: 'Maximum number of page views per author',
        min: 'Minimum number of page views per author',
        default: 'Total number of page views for all authors',
        median: 'Median number of page views per author'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorArticlesCountAggregateWidget = {
    defaultMetric: 'default',
    title: 'Articles',
    hint: {
        avg: 'Average number of articles written by one author in the selected period. If article is published in more than one Publication, it is counted for each Publication',
        max: 'Number of articles written by the most productive author in the selected period.',
        min: 'Number of articles written by the least productive author in the selected period.',
        default:
            'Total number of articles written by all authors in the selected period. If article is published in more than one Publication, it is counted for each Publication',
        median: 'Median number of articles written by one author in the selected period. If article is published in more than one Publication, it is counted for each Publication'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorPageViewsPerArticleAggregateWidget = {
    title: 'Page Views / Article',
    hint: {
        avg: 'Average per author number of page views per article',
        max: 'Maximum per author number of page views per article',
        min: 'Minimum per author number of page views per article',
        default: 'Total per author number of page views per article',
        median: 'Median per author number of page views per article'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorAvgSessionDurationAggregateWidget = {
    defaultMetric: 'default',
    title: 'Avg. Session Duration',
    hint: {
        default: 'Avg. Session Duration for all authors in the selected period',
        avg: 'Average Avg. Session Duration for all authors in the selected period',
        max: 'Maximum Avg. Session Duration for all authors in the selected period',
        min: 'Minimum Avg. Session Duration for all authors in the selected period',
        median: 'Median Avg. Session Duration for all authors in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorTotalSessionsDurationAggregateWidget = {
    defaultMetric: 'default',
    title: 'Total Sessions Duration',
    hint: {
        avg: 'Average Total Sessions Duration for all authors in the selected period',
        max: 'Maximum Total Sessions Duration for all authors in the selected period',
        min: 'Minimum Total Sessions Duration for all authors in the selected period',
        median: 'Median Total Sessions Duration for all authors in the selected period',
        default:
            'Total sessions duration for all authors in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorCostPerUserAggregateWidget = {
    title: '$ / User',
    hint: {
        default: 'Cost per user',
        avg: 'Average per author cost per user',
        max: 'Maximum per author cost per user',
        min: 'Minimum per author cost per user',
        sum: 'Total per author cost per user',
        median: 'Median per author cost per user'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorCostPerArticleAggregateWidget = {
    title: '$ / Article',
    hint: {
        default: 'Cost per article',
        avg: 'Average per author cost per article',
        max: 'Maximum per author cost per article',
        min: 'Minimum per author cost per article',
        sum: 'Total per author cost per article',
        median: 'Median per author cost per article'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSocialReferralsAggregateWidget = {
    title: 'Social Referrals',
    hint: {
        avg: 'Average number of social referrals per author',
        max: 'Maximum number of social referrals per author',
        min: 'Minimum number of social referrals per author',
        default: 'Total number of social referrals for all authors',
        median: 'Median number of social referrals per author'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSocialReferralsPerArticleAggregateWidget = {
    title: 'Social Referrals / Article',
    hint: {
        default:
            'Avg. Social Referrals / Article for all authors in the selected period',
        max: 'Maximum Social Referrals / Article for all authors in the selected period',
        min: 'Minimum Social Referrals / Article for all authors in the selected period',
        median: 'Median Social Referrals / Article for all authors in the selected period'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorCostPerSocialReferralAggregateWidget = {
    title: '$ / Social Referral',
    hint: {
        default: 'Average per author cost per social referral',
        max: 'Maximum per author cost per social referral',
        min: 'Minimum per author cost per social referral',
        sum: 'Total per author cost per social referral',
        median: 'Median per author cost per social referral'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSearchReferralsAggregateWidget = {
    title: 'Search Referrals',
    hint: {
        avg: 'Average number of search referrals per author',
        max: 'Maximum number of search referrals per author',
        min: 'Minimum number of search referrals per author',
        default: 'Total number of search referrals for all authors',
        median: 'Median number of search referrals per author'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorSearchReferralsPerArticleAggregateWidget = {
    title: 'Search Referrals / Article',
    hint: {
        default:
            'Search Referrals / Article for all authors in the selected period',
        max: 'Maximum Search Referrals / Article for all authors in the selected period',
        min: 'Minimum Search Referrals / Article for all authors in the selected period',
        median: 'Median Search Referrals / Article for all authors in the selected period'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const AuthorCostPerSearchReferralAggregateWidget = {
    title: '$ / Search Referral',
    hint: {
        default: 'Cost per search referral',
        avg: 'Average per author cost per search referral',
        max: 'Maximum per author cost per search referral',
        min: 'Minimum per author cost per search referral',
        sum: 'Total per author cost per search referral',
        median: 'Median per author cost per search referral'
    },
    defaultMetric: 'default'
};
