import { CacheMap } from 'smg-iq-utils';

import { asyncFetch } from 'src/api/utils/asyncFetch';

const cache = new CacheMap(1000 * 60 * 5);

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {string} query
 * @returns {Promise<import('smg-iq').ApiCallResult<Array<{country: string; regions: string[];}>>>}
 */
export const search = async (query) => {
    if (cache.has(query)) {
        return cache.get(query);
    }
    const countries = asyncFetch('countries/search', requestConfig({ query }));
    cache.set(query, countries);

    return countries;
};
