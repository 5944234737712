/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleArticlesCountAggregateWidget = {
    defaultMetric: 'default',
    title: 'Articles',
    hint: {
        default:
            'Total number of articles written by all authors in the selected period. If article is published in more than one Publication, it is counted for each Publication'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleUsersAggregateWidget = {
    defaultMetric: 'default',
    title: 'Users',
    hint: {
        default: 'Total number of uniq users in the selected period',
        sum: 'Sum of uniq users per article in the selected period',
        avg: 'Average number of uniq users per article in the selected period',
        max: 'Maximum number of uniq users per one article in the selected period',
        min: 'Minimum number of uniq users per one article in the selected period',
        median: 'Median number of uniq users per article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleUsersPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Users / Article',
    hint: {
        min: 'Minimum number of uniq users per one article in the selected period',
        max: 'Maximum number of uniq users per one article in the selected period',
        avg: 'Average number of uniq users per article in the selected period',
        default:
            'Total number of uniq users per article in the selected period',
        median: 'Median number of uniq users per article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleSessionsAggregateWidget = {
    defaultMetric: 'default',
    title: 'Sessions',
    hint: {
        default: 'Total number of sessions in the selected period',
        avg: 'Average number of sessions per article in the selected period',
        max: 'Maximum number of sessions per one article in the selected period',
        min: 'Minimum number of sessions per one article in the selected period',
        median: 'Median number of sessions per article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleSessionsPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Sessions / Article',
    hint: {
        min: 'Minimum sessions for one article in the selected period',
        max: 'Maximum sessions for one article in the selected period',
        default: 'Average sessions for one article in the selected period',
        median: 'Median sessions for one article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticlePageViewsAggregateWidget = {
    defaultMetric: 'default',
    title: 'Page Views',
    hint: {
        default: 'Total number of page views in the selected period',
        sum: 'Sum of page views among articles in the selected period',
        avg: 'Average number of page views per article in the selected period',
        max: 'Max page views of one article in the selected period',
        min: 'Min page views of one article in the selected period',
        median: 'Median page views of one article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticlePageViewsPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Page Views / Article',
    hint: {
        min: 'Minimum page views for one article in the selected period',
        max: 'Maximum page views for one article in the selected period',
        default: 'Average page views per article in the selected period',
        median: 'Median page views for one article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleCostPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Cost / Article',
    hint: {
        min: 'Minimum cost of one article in the selected period',
        max: 'Maximum cost of one article in the selected period',
        default: 'Average cost of one article in the selected period',
        median: 'Median cost of one article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleCostPerUserAggregateWidget = {
    defaultMetric: 'default',
    title: 'Cost / User',
    hint: {
        min: 'Minimum cost per user in the selected period',
        max: 'Maximum cost per user in the selected period',
        default: 'Average cost per user in the selected period',
        median: 'Median cost per user in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleAvgSessionDurationAggregateWidget = {
    defaultMetric: 'default',
    title: 'Avg Session Duration',
    hint: {
        min: 'Minimum average session duration for one article in the selected period',
        max: 'Maximum average session duration for one article in the selected period',
        default:
            'Average average session duration for one article in the selected period',
        median: 'Median average session duration for one article in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const ArticleTotalSessionsDurationAggregateWidget = {
    defaultMetric: 'default',
    title: 'Total Sessions Duration',
    hint: {
        min: 'Minimum total sessions duration for one article in the selected period',
        max: 'Maximum total sessions duration for one article in the selected period',
        default:
            'Average total sessions duration for one article in the selected period',
        median: 'Median total sessions duration for one article in the selected period',
        sum: 'Total total sessions duration for all articles in the selected period'
    }
};
