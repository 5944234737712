import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = {
    method: 'GET'
};

/**
 * @returns {Promise<import('smg-iq').ApiCallResult<Array<import('smg-iq').UserDTO>>>}
 */
const getAllUsers = () => asyncFetch('users/all', requestConfig);

export { getAllUsers };
