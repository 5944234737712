import React from 'react';

/**
 * @typedef {'info' | 'error' | 'success'} NotificationType
 */

/**
 * @callback NotificationCallback
 * @param {*} value
 */

const context = {
    listeners: {},
    showInfo(str) {
        context.emit('info', str);
    },

    showError(err) {
        context.emit('error', err);
    },

    showSuccess(msg) {
        context.emit('success', msg);
    },

    /**
     *
     * @param {NotificationType} event
     * @param {NotificationCallback} callback
     * @return {void}
     */
    on(event, callback) {
        if (!callback) return;
        if (!Array.isArray(context.listeners[event])) {
            context.listeners[event] = [callback];
            return;
        }
        context.listeners[event].push(callback);
    },

    /**
     *
     * @param {NotificationType} event
     * @param {*} data
     * @return {void}
     */
    emit(event, data) {
        if (Array.isArray(context.listeners[event])) {
            for (let i = 0; i < context.listeners[event].length; i++) {
                context.listeners[event][i](data);
            }
        }
    }
};
const NotificationsContext = React.createContext(context);

export { NotificationsContext, context };
