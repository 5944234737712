import { ACTION_TYPES, INITIAL_STATE, RESPONSE_STATUSES } from '../constants';

const decorateResponse = ({ type, payload }) => {
    const statusMap = {
        [ACTION_TYPES.request]: () => ({
            ...INITIAL_STATE,
            status: RESPONSE_STATUSES.fetching
        }),
        [ACTION_TYPES.success]: () => ({
            ...INITIAL_STATE,
            status: RESPONSE_STATUSES.fetched,
            success: true,
            data: payload
        }),
        [ACTION_TYPES.application_error]: () => ({
            ...INITIAL_STATE,
            status: RESPONSE_STATUSES.application_error,
            success: false,
            error: payload
        }),
        [ACTION_TYPES.failure]: () => ({
            ...INITIAL_STATE,
            status: RESPONSE_STATUSES.transport_error,
            success: false,
            error: payload
        }),
        [ACTION_TYPES.unathorized]: () => ({
            ...INITIAL_STATE,
            status: RESPONSE_STATUSES.unathorized,
            success: false,
            error: payload
        }),
        [ACTION_TYPES.forbidden]: () => ({
            ...INITIAL_STATE,
            status: RESPONSE_STATUSES.forbidden,
            success: false,
            error: payload
        })
    };

    const response = statusMap[type] ? statusMap[type]() : INITIAL_STATE;
    return response;
};

export { decorateResponse };
