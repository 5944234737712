import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 * @param {import('smg-iq').GetColumnGroupsForPublicationRequest} request
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').ColumnGroup[]>>}
 */
export const getColumnGroupsForPublication = async (request) =>
    await asyncFetch(
        'analytics/get-column-groups-for-publication',
        requestConfig({
            ...request
        })
    );
