import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {string} email
 * @param {Array<string>} roles
 * @returns {Promise<import('smg-iq').ApiCallResult<any>>}
 */
const setUserRoles = (email, roles) =>
    asyncFetch(
        'users/set-roles',
        requestConfig({
            email,
            roles
        })
    );

export { setUserRoles };
