import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 * @param {number} publicationId;
 * @returns {Promise<import('smg-iq').ApiCallResult<Array<import('smg-iq').EditorialAnalyticsSetting>>>}
 */
const getSettings = (publicationId) =>
    asyncFetch(
        'analytics/settings',
        requestConfig({
            publicationId
        })
    );

export { getSettings };
