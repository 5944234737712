/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsPageViewsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.pageViews',
        apiName: 'pageViews',
        format: 'integer',
        label: 'Page Views',
        hint: 'Total count of page views for source',
        sortable: true,
        isDefaultSortColumn: true
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsPageViewsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.pageViews',
        apiName: 'pageViewsHistory',
        format: 'history',
        label: 'Page Views Trend',
        hint: 'Page Views Trend',
        sortable: false
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsArticlesCountColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.articlesCount',
        apiName: 'articlesCount',
        format: 'integer',
        label: 'Articles',
        hint: 'Number of articles in the selected period. If article is published in more than one Publication, it is counted for each Publication',
        sortable: false,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsNewVisitorsColumn = {
    column: {
        type: 'event',
        apiName: 'newVisitors',
        sortable: true,
        label: 'New Visitors',
        hint: 'Number of new visitors in the selected period',
        groupByOperation: 'sum',
        calculation: 'count',
        eventName: 'first_visit',
        format: 'integer'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsNewVisitorsHistoryColumn = {
    column: {
        type: 'event_history',
        apiName: 'newVisitorsHistory',
        sortable: false,
        label: 'New Visitors Trend',
        hint: 'Trend for new visitors in the selected period',
        groupByOperation: 'sumForEach',
        calculation: 'count',
        eventName: 'first_visit',
        format: 'history'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsSessionsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.sessions',
        apiName: 'sessions',
        format: 'integer',
        label: 'Sessions',
        hint: 'Number of sessions',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsSessionsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.sessions',
        apiName: 'sessionsHistory',
        format: 'history',
        label: 'Sessions Trend',
        hint: 'Number of sessions',
        sortable: false,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsUsersColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.users',
        apiName: 'users',
        format: 'integer',
        label: 'Users',
        hint: "Count of uniq users who visited author's articles in the selected period",
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsUsersPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.usersPerArticle',
        apiName: 'usersPerArticle',
        format: 'decimal',
        label: 'Users / Article',
        hint: 'Number of uniq users divided by the number of articles',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsSessionsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.sessionsPerArticle',
        apiName: 'sessionsPerArticle',
        format: 'decimal',
        label: 'Sessions / Article',
        hint: 'Number Sessions divided by the number of Articles',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const MetadataReferralsPageViewsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.pageViewsPerArticle',
        apiName: 'pageViewsPerArticle',
        format: 'decimal',
        label: 'Page Views / Article',
        hint: 'Number of page views divided by the number of articles',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};
