const { format } = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

/**
 * @param {number} amount
 * @returns {string}
 */
export default format;
