import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('@types/smg-iq/authors.analytics').SaveMonthDataRequest} req
 * @returns {Promise<import('smg-iq').ApiCallResult<import('@types/smg-iq/authors.analytics').GetAuthorsMonthlyDataResponse>>}
 */
const saveMonthData = (req) =>
    asyncFetch('analytics/save-month-data', requestConfig(req));

export { saveMonthData };
