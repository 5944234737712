import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').SearchTagsRequest} spec
 * @returns {Promise<import('smg-iq').ApiCallResult<string[]>>}
 */
const searchTags = async (spec) =>
    asyncFetch('analytics/search-tags', requestConfig(spec));
export { searchTags };
