import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { GlobalStyles } from 'src/components/shared/GlobalStyles';
// import 'src/mixins/chartjs';
import { theme } from 'src/theme';
import { routes } from 'src/routes';
import { AuthProvider } from 'src/components/providers/AuthProvider';
import { NotificationProvider } from 'src/components/providers/NotificationProvider';
import { GlobalProvider } from 'src/components/providers/GlobalProvider';

const App = () => {
    const routing = useRoutes(routes);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <GlobalProvider>
                    <NotificationProvider>
                        <AuthProvider>{() => routing}</AuthProvider>
                    </NotificationProvider>
                </GlobalProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export { App };
