import { EditorialAnalyticsConfig } from 'smg-iq-configs';

const now = new Date();

/**
 * @type {import('smg-iq').EditorialReportSpecification}
 */
export const defaultSpecification = {
    type: 'author',
    granularity: 'month',
    range: {
        from: new Date(Date.UTC(2023, 3, 1)),
        to: new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 2, 0))
    },
    selection: 'last-month',
    showBy: 'visitors',
    orderBy: 'general.visitors',
    orderDirection: 'desc',
    columnGroups: ['general', 'financial'],
    filters: {
        article: {
            articlePublishDate: {
                operation: 'between',
                value: 'last-month'
            },
            includeNonCanonical: false,
            articleType: {
                operation: 'in',
                value: Object.keys(
                    EditorialAnalyticsConfig.ArticleTypesDefaultValues
                )
                    .filter(
                        (key) =>
                            EditorialAnalyticsConfig.ArticleTypesDefaultValues[
                                key
                            ]
                    )
                    .sort()
            }
        },
        geography: [
            {
                countryCode: 'United States'
            },
            {
                countryCode: 'Canada'
            }
        ]
    },
    metrics: [
        'avgBounceRate',
        'avgSessionDuration',
        'articlesPublished',
        'visitorsPerArticle',
        'newVisitors',
        'searchReferrals',
        'socialReferrals',
        'views',
        'visitors'
    ]
};
