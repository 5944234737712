const now = new Date();

/**
 * @type {import('smg-iq').MarketingReportSpecification}
 */
export const defaultSpecification = {
    type: 'campaign',
    granularity: 'month',
    range: {
        from: new Date(Date.UTC(2023, 3, 1)),
        to: new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 2, 0))
    },
    selection: 'last-month',
    showBy: 'visitors',
    orderBy: 'general.users',
    orderDirection: 'desc',
    columnGroups: ['general'],
    filters: {
        article: {
            includeNonCanonical: false
        },
        geography: [
            {
                countryCode: 'United States'
            },
            {
                countryCode: 'Canada'
            }
        ]
    },
    metrics: ['views', 'newVisitors', 'visitors', 'events']
};
