import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {number} publicationId
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').SearchArticlesResponse>>}
 */
export const getHiddenArticles = async (publicationId) =>
    asyncFetch(
        'analytics/get-hidden-articles',
        requestConfig({ publicationId })
    );
