import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 * @param {import('smg-iq').GetIndividualReportsStatesForAuthor} request
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').IndividualReportStateResult[]>>}
 */
export const getIndividualReportsStatesForAuthor = async (request) =>
    asyncFetch(
        'analytics/get-individual-report-state',
        requestConfig({
            ...request
        })
    );
