import { CacheMap } from 'smg-iq-utils';
import { asyncFetch } from 'src/api/utils/asyncFetch';

const cache = new CacheMap(1000 * 60 * 5);

const requestConfig = (data) => ({
    method: 'GET',
    data
});

/**
 *
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').ArticleTypes>}
 */
const getArticleTypes = async () => {
    if (cache.has('types')) {
        return cache.get('types');
    }
    const types = await asyncFetch(
        'analytics/article-types',
        requestConfig({})
    );
    cache.set('types', types);
    return types;
};
export { getArticleTypes };
