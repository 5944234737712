import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {number} publicationId
 * @param {string} articleId
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').ArticleDTO[]>>}
 */
export const unhideArticle = async (publicationId, articleId) =>
    asyncFetch(
        'analytics/unhide-article',
        requestConfig({ publicationId, articleId })
    );
