import { padLeft } from './pad.js';

/**
 * @param {Number} minutes
 * @retruns {String}
 */
export default (minutes) =>
    `${
        minutes >= 60 * 24
            ? `${Math.floor(minutes / (60 * 24)).toFixed(0)}d `
            : ''
    }${padLeft(
        Math.floor(
            (minutes - Math.floor(minutes / (60 * 24)).toFixed(0) * 60 * 24) /
                60
        ).toFixed(0),
        2,
        '0'
    )}h ${(minutes % 60).toFixed(0)}m`;
