import {
    getWeekFirstDay,
    addDays,
    getDayNameLong,
    getDayNameNarrow
} from './dateFunctions.js';

const weekStart = getWeekFirstDay(new Date());

const res = [
    ...Array.from(Array(7).keys()).map((d) => {
        const day = addDays(weekStart, d);
        const dayNameLong = getDayNameLong(day);
        const dayNameNarrow = getDayNameNarrow(day);
        return {
            index: d === 0 ? 7 : d,
            label: dayNameNarrow,
            value: dayNameLong
        };
    })
];

const sunday = res.shift();

export const weekDays = [...res, sunday];
