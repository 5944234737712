import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').AuthorsStatsQuery} authorsStatsQuery
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').AuthorsStatsDTO>>}
 */
const getAuthorsStats = (authorsStatsQuery) =>
    asyncFetch('authors-stats', requestConfig(authorsStatsQuery));

export { getAuthorsStats };
