import { FrontendConfig } from 'smg-iq-configs';

import { INITIAL_STATE } from '../constants.js';

import { fetchData } from './fetchData.js';

import { decorateRequestConfig } from './decorateRequestConfig.js';

/**
 *
 * @param {string} endpoint
 * @param {import('axios').AxiosRequestConfig<any>} requestConfig
 * @param {boolean} notCurrentBaseUrl
 * @returns {Promise<import('smg-iq').ApiCallResult>}
 */
async function asyncFetch(endpoint, requestConfig, notCurrentBaseUrl) {
    if (endpoint && !notCurrentBaseUrl)
        endpoint = `${FrontendConfig.BackendBaseUrl}/${endpoint}`;

    if (!endpoint) {
        return INITIAL_STATE;
    }

    const decoratedRequestConfig = decorateRequestConfig(requestConfig);
    const state = await fetchData(endpoint, decoratedRequestConfig);

    return state;
}

export { asyncFetch };
