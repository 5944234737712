import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 * @param {import('smg-iq').UpdateIndividualReportRequest} request
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').IndividualReportDTO[]>>}
 */
export const updateIndividualReportsForPublication = async (request) =>
    await asyncFetch(
        'analytics/update-individual-report',
        requestConfig({
            ...request
        })
    );
