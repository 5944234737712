const DEFAULT_ERROR_MESSAGE = 'Failed to load data';

const RESPONSE_STATUSES = {
    init: 'init',
    application_error: 'application_error',
    transport_error: 'transport_error',
    fetching: 'fetching',
    fetched: 'fetched',
    unathorized: 'unathorized',
    forbidden: 'forbidden'
};

const ACTION_TYPES = {
    request: 'request',
    success: 'success',
    application_error: 'application_error',
    failure: 'failure',
    unathorized: 'unathorized',
    forbidden: 'forbidden'
};

const INITIAL_STATE = {
    status: RESPONSE_STATUSES.init,
    error: undefined,
    data: undefined
};

export {
    RESPONSE_STATUSES,
    ACTION_TYPES,
    INITIAL_STATE,
    DEFAULT_ERROR_MESSAGE
};
