import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').SearchArticlesRequest} spec
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').SearchArticlesResponse>>}
 */
const searchArticles = async (spec) =>
    asyncFetch('analytics/search-articles', requestConfig(spec));
export { searchArticles };
