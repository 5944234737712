export default {
   "roles": {
      "ADMIN": "admin",
      "EDITORIAL_MANAGER": "editorial_manager",
      "EDITORIAL_ADMIN": "editorial_admin"
   },
   "names": {
      "admin": "Admin",
      "editorial_manager": "Editorial Manager",
      "editorial_admin": "Editorial Admin"
   }
};