import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useLocation, Navigate } from 'react-router-dom';

import { AuthConfig } from 'smg-iq-configs';
import { deleteCookie, getCookie } from '../../../utils/authUtils';
import {
    AuthContext,
    defaultAuthState,
    commands
} from '../../../contexts/AuthContext';
import { validateToken } from 'src/api/requests/auth';
import { RESPONSE_STATUSES } from 'src/api/constants';

const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [userIsValid, setUserIsValid] = useState(false);

    const [authState, setAuthState] = useState(defaultAuthState);

    const location = useLocation();

    /**
     * @returns {Promise<import('smg-iq').UserDTO| false>}
     */
    const validateUser = async () => {
        const cookieValue = getCookie(AuthConfig.CookieName);

        if (cookieValue) {
            const {
                data: user,
                success,
                status
            } = await validateToken(cookieValue);
            if (success) {
                return user;
            } else {
                if (status !== RESPONSE_STATUSES.transport_error) {
                    // don't stop the session if it's just a connection issue.
                    deleteCookie(AuthConfig.CookieName);
                }
                return false;
            }
        } else {
            return false;
        }
    };

    useEffect(() => {
        const checkState = async () => {
            try {
                const user = await validateUser();
                setUserIsValid(user);
                if (user) {
                    setAuthState({
                        authenticated: true,
                        user,
                        commands: { ...commands }
                    });
                } else {
                    setAuthState(defaultAuthState);
                }
                setLoading(false);
            } catch {
                deleteCookie(AuthConfig.CookieName);
                setUserIsValid(false);
                setLoading(false);
            }
        };

        checkState();
    }, []);

    return (
        <>
            {loading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh'
                    }}
                >
                    <CircularProgress />
                </div>
            )}
            {!loading &&
                (userIsValid || location.pathname.startsWith('/login')) && (
                    <AuthContext.Provider value={authState}>
                        {children()}
                    </AuthContext.Provider>
                )}
            {!loading &&
                !(userIsValid || location.pathname.startsWith('/login')) && (
                    <Navigate
                        to={`/login?to=${encodeURIComponent(
                            `${location.pathname}${location.search}`
                        )}`}
                        replace={false}
                    />
                )}
        </>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.func
};

export { AuthProvider };
