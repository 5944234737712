const { format } = Intl.DateTimeFormat(
    typeof navigator === 'undefined' ? 'en-us' : navigator.language,
    {
        month: 'long'
    }
);

export const monthsNames = [...new Array(12)].map((v, index) => ({
    index,
    name: format(new Date(2023, index, 1))
}));
