/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const MetadataReferralsArticlesCountAggregateWidget = {
    defaultMetric: 'default',
    title: 'Articles',
    hint: {
        default:
            'Total number of articles in the selected period. If article is published in more than one Publication, it is counted for each Publication'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const MetadataReferralsUsersAggregateWidget = {
    defaultMetric: 'default',
    title: 'Users',
    hint: {
        default: 'Total number of users in the selected period.'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const MetadataReferralsUsersPerArticleAggregateWidget = {
    defaultMetric: 'default',
    title: 'Users / Article',
    hint: {
        default:
            'Average Users / Article for all authors in the selected period'
    }
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const MetadataReferralsSessionsAggregateWidget = {
    title: 'Sessions',
    hint: {
        default: 'Total number of sessions for all authors'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const MetadataReferralsSessionsPerArticleAggregateWidget = {
    title: 'Sessions / Article',
    hint: {
        default: 'Average per author number of sessions per article'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const MetadataReferralsPageViewsAggregateWidget = {
    title: 'Page Views',
    hint: {
        default: 'Total number of page views'
    },
    defaultMetric: 'default'
};

/**
 * @type {import('smg-iq').AggregateWidget}
 */
export const MetadataReferralsPageViewsPerArticleAggregateWidget = {
    title: 'Page Views / Article',
    hint: {
        default: 'Average per author number of page views per article'
    },
    defaultMetric: 'default'
};
