import { CacheMap } from 'smg-iq-utils';
import { asyncFetch } from 'src/api/utils/asyncFetch';

const cache = new CacheMap(1000 * 60 * 5);

const requestConfig = (data) => ({
    method: 'GET',
    data
});

/**
 *
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').AuthorTypeDTO[]>>}
 */
export const getAuthorTypes = async () => {
    if (cache.has('authorTypes')) {
        return cache.get('authorTypes');
    }
    const authorTypes = await asyncFetch(
        'analytics/author-types',
        requestConfig({})
    );

    cache.set('authorTypes', authorTypes);
    return authorTypes;
};
