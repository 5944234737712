/**
 * @param {String} src
 * @param {Number} length
 * @param {String} [chr = '0']
 * @returns {String}
 */
export const padLeft = (src, length, chr = '0') =>
    src.length >= length
        ? src
        : `${new Array(length - src.length).fill(chr)}${src}`;
