import { findUser } from './findUser';
import { fullInfo } from './fullInfo.js';
import { deleteUser } from './deleteUser';
import { addUser } from './addUser';
import { setUserRoles } from './setUserRoles';
import { getAllUsers } from './getAllUsers';
import { setApiKey } from './setApiKey';

export const UsersApi = {
    findUser,
    fullInfo,
    deleteUser,
    addUser,
    setUserRoles,
    getAllUsers,
    setApiKey
};
