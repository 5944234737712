import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {string} id
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').AuthorDTO>>}
 */
const getAuthorById = (id) =>
    asyncFetch(
        'author/get-by-id',
        requestConfig({
            id
        })
    );

export { getAuthorById };
