import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').PageQuery} pageQuery
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').PageResult<import('smg-iq').AuthorDTO>>>}
 */
const getAuthors = (pageQuery) =>
    asyncFetch('authors', requestConfig(pageQuery));

export { getAuthors };
