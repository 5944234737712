import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {string} email
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').User>>}
 */
const fullInfo = (email) =>
    asyncFetch('users/full_info', requestConfig({ email }));

export { fullInfo };
