import * as React from 'react';
import PropTypes from 'prop-types';
import { GlobalContext } from 'src/contexts/GlobalContext';
import { useGlobalProvider } from 'src/hooks/useProvider';

const GlobalProvider = ({ children }) => (
    <GlobalContext.Provider value={useGlobalProvider()}>
        {children}
    </GlobalContext.Provider>
);

export { GlobalProvider };

GlobalProvider.propTypes = {
    children: PropTypes.element.isRequired,
    defaultData: PropTypes.object
};
