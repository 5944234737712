import { EventEmitter } from 'events';

export default class Collector extends EventEmitter {
    #collectionResults = null;

    #leftToCollect = 0;

    #timeoutTimer = null;

    #isTimedOut = false;

    #promiseResolve = null;

    #promiseReject = null;

    /**
     *
     * @param {Array<String>} keys - keys to collect
     * @param {Number} [timeout] - if specified 'timeout' event will be fired if all items are not collected when `timeout` milliseconds pass after creation of a Collector
     */
    constructor(keys, timeout = -1) {
        super();
        if (!Array.isArray(keys))
            throw new Error('keys must be of time Array<String>');

        this.#leftToCollect = Object.keys(keys).length;
        this.#collectionResults = {};

        if (typeof timeout === 'number' && timeout > 0) {
            this.#timeoutTimer = setTimeout(() => {
                if (this.#leftToCollect <= 0) return;
                if (this.#isTimedOut) return;
                this.#isTimedOut = true;
                this.emit('timeout', { ...this.#collectionResults });
                if (typeof this.#promiseReject === 'function')
                    this.#promiseReject({
                        reason: 'timeout',
                        results: { ...this.#collectionResults }
                    });
            }, timeout);
        }
    }

    #onEnd() {
        clearTimeout(this.#timeoutTimer);
        this.emit('end', { ...this.#collectionResults });
        if (typeof this.#promiseResolve === 'function')
            this.#promiseResolve({ ...this.#collectionResults });
    }

    /**
     *
     * @param {String} key
     * @param {*} value
     */
    collect(key, value) {
        if (this.#isTimedOut) return;
        // if value is Promise - await it and collect its result
        Promise.resolve(value)
            .then((result) => {
                if (this.#isTimedOut) return;
                if (key in this.#collectionResults)
                    throw new Error(
                        `Item with key '${key}' is already collected`
                    );

                this.#collectionResults[key] = result;
                this.#leftToCollect--;
                if (this.#leftToCollect === 0) {
                    this.#onEnd();
                }
            })
            .catch((e) => {
                if (typeof this.#promiseReject === 'function') {
                    this.#promiseReject({
                        reason: 'error',
                        results: { key, error: e }
                    });
                } else {
                    this.emit('error', {
                        key,
                        error: e
                    });
                }
            });

        // eslint-disable-next-line
        return this;
    }

    /**
     * @returns {Promise<Object>}
     */
    toPromise() {
        return new Promise((res, rej) => {
            this.#promiseResolve = res;
            this.#promiseReject = rej;
        });
    }
}
