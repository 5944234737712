import { CampaignSessionCampaignColumn } from '../../reports/marketing/campaign/defaultColumns.js';
import { ContentSessionContentColumn } from '../../reports/marketing/content/defaultColumns.js';
import { UrlPageUrlColumn } from '../../reports/marketing/url/defaultColumns.js';

export const EMPTY_GROUP_BY_KEY = 'none';

/**
 * @type {Record<import('smg-iq').MarketingReportSpecification['type'], Record<import('smg-iq').MarketingReportSpecification['showBy'], Record<import('smg-iq').MarketingReportSpecification['groupBy'] | 'none',  import('smg-iq').ReportColumnWithGroup[]>>}
 */
export const DefaultReportColumns = {
    campaign: {
        visitors: {
            none: [CampaignSessionCampaignColumn]
        },
        views: {
            none: [CampaignSessionCampaignColumn]
        },
        newVisitors: {
            none: [CampaignSessionCampaignColumn]
        }
    },
    content: {
        visitors: {
            none: [ContentSessionContentColumn]
        },
        views: {
            none: [ContentSessionContentColumn]
        },
        newVisitors: {
            none: [ContentSessionContentColumn]
        }
    },
    url: {
        visitors: {
            none: [UrlPageUrlColumn]
        },
        views: {
            none: [UrlPageUrlColumn]
        },
        newVisitors: {
            none: [UrlPageUrlColumn]
        }
    }
};

export const DefaultReportColumnsMap = {
    'default.users': 'Users',
    'default.usersPerArticle': 'Users per article',
    'default.sessions': 'Sessions',
    'default.sessionsPerArticle': 'Sessions per article',
    'default.pageViews': 'Page views',
    'default.articlesCount': 'Articles',
    'default.pageViewsPerArticle': 'Page views per article',
    'default.uniqArticlesCount': 'Unique articles',
    'default.avgSessionDuration': 'Avg. session duration',
    'default.totalSessionsDuration': 'Total sessions duration',
    'default.costPerUser': 'Cost per user',
    'default.costPerSession': 'Cost per session',
    'default.costPerArticle': 'Cost per article',
    'default.costPerPageView': 'Cost per page view',
    'default.socialReferrals': 'Social referrals',
    'default.socialReferralsPerArticle': 'Social referrals per article',
    'default.costPerSocialReferral': 'Cost per social referral',
    'default.searchReferrals': 'Search referrals',
    'default.searchReferralsPerArticle': 'Search referrals per article',
    'default.costPerSearchReferral': 'Cost per search referral'
};

/**
 *
 * @param {{
 *      type: import('smg-iq').MarketingReportSpecification['type'];
 *      showBy: import('smg-iq').MarketingReportSpecification['showBy'];
 *      groupBy: import('smg-iq').MarketingReportSpecification['groupBy'];
 *      columnGroups: import('smg-iq').MarketingReportSpecification['columnGroups'];
 * }} spec
 * @param {import('smg-iq').ISimpleColumnGroupsRepository} columnGroupsRepository
 * @param {boolean} [onlySortable]
 * @returns {Promise<import('smg-iq').ReportColumnWithGroup[]>}
 */
export const getPossibleSortColumns = async (
    spec,
    columnGroupsRepository,
    onlySortable
) => {
    const res = await columnGroupsRepository.getReportColumns(
        'marketing',
        spec
    );
    return res.filter(
        (c) =>
            (onlySortable ? c.column.sortable : true) && !c.column.hideFromTable
    );
};

/**
 * @param {import('smg-iq').MarketingReportSpecification} specification
 * @returns {boolean}
 */
export const isTableTypeReport = () => true;
