import { AuthConfig } from 'smg-iq-configs';
import { getCookie } from '../../utils/authUtils';

const getRequestHeaders = ({ data }) => {
    const token = getCookie(AuthConfig.CookieName);

    return {
        'Cache-Control': 'no-cache',
        ...(token && { Authorization: token }),
        ...(data && { 'Content-Type': 'application/json' })
    };
};

export { getRequestHeaders };
