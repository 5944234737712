// eslint-disable-next-line import/no-extraneous-dependencies
import { ModelBinder } from 'react-url-params-model-binder';

import { FrontendConfig } from 'smg-iq-configs';

import { urlParamBindings } from './urlParamBindings.js';

/**
 * @type {import('react-url-params-model-binder').ModelBinder<import('smg-iq').MarketingReportSpecification>}
 */
let modelBinder = new ModelBinder();

for (const paramBinder of urlParamBindings) {
    modelBinder = modelBinder.addParamBinder(paramBinder);
}

/**
 *
 * @param {URLSearchParams} params
 * @returns {{
 *      specification?: import('smg-iq').MarketingReportSpecification;
 *      redirectToParams?: Record<string, string>;
 * }}
 */
export const urlParametersToReportSpecification = async (params) => {
    const { model, newParams } = await modelBinder.getModelFromUrlParams(
        params
    );

    if (newParams) {
        return {
            redirectToParams: newParams
        };
    }
    return { specification: model };
};

/**
 *
 * @param {import('smg-iq').MarketingReportSpecification} specification
 * @param {URLSearchParams} [searchParams]
 * @returns {Promise<URLSearchParams>}
 */
export const getUrlParametersFromReportSpecification = async (
    specification,
    searchParams
) => modelBinder.getUrlParamsFromModel(specification, searchParams);

/**
 * @param {import('smg-iq').MarketingReportSpecification} specification
 * @returns {Promise<string>}
 */
export const reportSpecificationToUrl = async (specification) => {
    const parameters = await getUrlParametersFromReportSpecification(
        specification
    );
    return `${
        FrontendConfig.FrontendBaseUrl
    }/app/analytics/reports/marketing?${parameters.toString()}`;
};
