import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data,
    raw: true
});

/**
 *
 * @param {import('smg-iq').ExportEditorialReportRequest} spec
 * @returns {Promise<import('smg-iq').ApiCallResult<any>>}
 */
export const exportMarketingReport = (spec) =>
    asyncFetch('analytics/marketing/export-report', requestConfig(spec));
