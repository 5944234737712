import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {String} token
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').ValidateTokenResponse>>}
 */
const validateToken = async (token) => {
    return asyncFetch('auth/validateToken', requestConfig({ token }));
};

export { validateToken };
