import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    Avatar,
    Typography,
    Button
} from '@mui/material';

import {
    DateRange as MonthsIcon,
    // Person as AuthorIcon,
    SaveOutlined as SaveOutlinedIcon,
    Logout as LogoutIcon,
    Analytics as AnalyticsIcon,
    // History as HistoryIcon,
    // ImportExport as ImportIcon,
    BarChart as BarChartIcon,
    PeopleAltOutlined as PeopleAltOutlinedIcon
} from '@mui/icons-material';
import { Settings as SettingsIcon, User as UserIcon } from 'react-feather';

import { UserRolesConfig } from 'smg-iq-configs';
import { urlParamsInfo } from 'smg-iq-schemas';

import { useNotification } from 'src/hooks/useNotification';
import { useApi } from 'src/hooks/useApi';
import { useAuthContext } from 'src/hooks/useContext';

import { NavItem } from '../NavItem/index.js';
import { ShowForRole } from '../ShowForRole/index.js';

const {
    getUrlParametersFromReportSpecification:
        editorialGetUrlParametersFromReportSpecification
} = urlParamsInfo.editorial;

const {
    getUrlParametersFromReportSpecification:
        marketingGetUrlParametersFromReportSpecification
} = urlParamsInfo.marketing;

const items = [
    {
        role: UserRolesConfig.roles.EDITORIAL_MANAGER,
        href: '/app/analytics',
        icon: AnalyticsIcon,
        title: 'Editorial Analytics',
        children: [
            {
                index: 1,
                href: '/app/analytics/publication-settings',
                role: [
                    UserRolesConfig.roles.EDITORIAL_ADMIN,
                    UserRolesConfig.roles.ADMIN
                ],
                icon: SettingsIcon,
                title: 'Publications'
            },
            {
                index: 2,
                href: '/app/analytics/months',
                matchedPaths: ['/app/analytics/months/:year/:month'],
                icon: MonthsIcon,
                title: 'Months'
            },
            {
                index: 3,
                href: '/app/analytics/authors-list',
                icon: PeopleAltOutlinedIcon,
                title: 'Authors'
            },
            {
                id: 'EDITORIAL_REPORTS',
                index: 4,
                href: '/app/analytics/reports/editorial',
                icon: BarChartIcon,
                title: 'Editorial Reports',
                children: []
            },
            {
                id: 'MARKETING_REPORTS',
                index: 5,
                href: '/app/analytics/reports/marketing',
                icon: BarChartIcon,
                title: 'Marketing Reports',
                children: []
            },
            {
                index: 6,
                role: [
                    UserRolesConfig.roles.EDITORIAL_ADMIN,
                    UserRolesConfig.roles.EDITORIAL_MANAGER,
                    UserRolesConfig.roles.ADMIN
                ],
                href: '/app/analytics/reports/saved-reports',
                icon: SaveOutlinedIcon,
                title: 'Saved reports'
            }
            // {
            //     index: 7,
            //     href: '/app/analytics/logs',
            //     role: [
            //         UserRolesConfig.roles.EDITORIAL_ADMIN,
            //         UserRolesConfig.roles.ADMIN
            //     ],
            //     icon: HistoryIcon,
            //     title: 'Logs'
            // },
            // {
            //     index: 8,
            //     href: '/app/analytics/ga-imports',
            //     role: [
            //         UserRolesConfig.roles.EDITORIAL_ADMIN,
            //         UserRolesConfig.roles.ADMIN
            //     ],
            //     icon: ImportIcon,
            //     title: 'GA Data Imports'
            // }
        ]
    },
    {
        role: UserRolesConfig.roles.ADMIN,
        href: '/admin/users',
        icon: SettingsIcon,
        title: 'Administration',
        children: [
            {
                index: 0,
                href: '/admin/users',
                icon: UserIcon,
                title: 'Users'
            },
            {
                index: 1,
                href: '/admin/system-settings',
                icon: SettingsIcon,
                title: 'System Settings'
            }
        ]
    }
];

export const DashboardSidebar = ({ onNavClose, isNavOpen }) => {
    const API = useApi();
    const showNotification = useNotification();
    const [editorialReportItems, setEditorialReportItems] = useState([]);
    const [marketingReportItems, setMarketingReportItems] = useState([]);

    const [savedEditorialReports, setSavedEditorialReports] = useState([]);
    const [savedMarketingReports, setSavedMarketingReports] = useState([]);

    const { user, commands } = useAuthContext();
    const location = useLocation();
    useEffect(() => {
        onNavClose();
    }, [location.pathname, location.search, onNavClose]);

    useEffect(() => {
        const fetchSavedReports = async () => {
            const [savedEditorialReports, savedMarketingReports] =
                await Promise.all([
                    API.EditorialAnalytics.getSavedEditorialReports(),
                    API.EditorialAnalytics.getSavedMarketingReports()
                ]);

            setSavedEditorialReports(savedEditorialReports);
            setSavedMarketingReports(savedMarketingReports);
        };
        fetchSavedReports();
    }, [API.EditorialAnalytics]);

    const deleteSavedReport = useCallback(
        async (id) => {
            const reportToDelete =
                await API.EditorialAnalytics.deleteSavedReport(id);
            if (reportToDelete) {
                showNotification('success', 'Report deleted');
            }
        },
        [API.EditorialAnalytics, showNotification]
    );

    useEffect(() => {
        const triggerReports = async () => {
            const [existingEditorialReports, existingMarketingReports] =
                await Promise.all([
                    await Promise.all(
                        savedEditorialReports.map(
                            async ({ id, name, specification }) => {
                                const urlParams =
                                    await editorialGetUrlParametersFromReportSpecification(
                                        specification
                                    );

                                return {
                                    id: id.toString(),
                                    title: name,
                                    href: `/app/analytics/reports/editorial?${urlParams.toString()}`,
                                    isActive: location.search === urlParams,
                                    onRemove: () => deleteSavedReport(id)
                                };
                            }
                        )
                    ),
                    await Promise.all(
                        savedMarketingReports.map(
                            async ({ id, name, specification }) => {
                                const urlParams =
                                    await marketingGetUrlParametersFromReportSpecification(
                                        specification
                                    );

                                return {
                                    id: id.toString(),
                                    title: name,
                                    href: `/app/analytics/reports/marketing?${urlParams.toString()}`,
                                    isActive: location.search === urlParams,
                                    onRemove: () => deleteSavedReport(id)
                                };
                            }
                        )
                    )
                ]);
            setEditorialReportItems(existingEditorialReports);
            setMarketingReportItems(existingMarketingReports);
        };

        triggerReports();
    }, [
        API,
        location.search,
        showNotification,
        savedEditorialReports,
        savedMarketingReports,
        deleteSavedReport
    ]);

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'auto'
            }}
        >
            <Box
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: 'text.secondary'
                }}
            >
                <Avatar
                    sx={{ width: '60px', height: '60px' }}
                    src={user.picture}
                />
                <Typography sx={{ p: 0, fontSize: '14px', paddingTop: '1em' }}>
                    Welcome,
                </Typography>
                <Typography
                    sx={{ p: 0, fontSize: '14px' }}
                >{`${user.firstName} ${user.lastName}`}</Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
                <List>
                    {items.map((item) => {
                        const children = item?.children?.map((child) => {
                            if (!child?.children) {
                                return child;
                            }
                            switch (child.id) {
                                case 'EDITORIAL_REPORTS':
                                    return Object.assign({}, child, {
                                        children: editorialReportItems
                                    });
                                case 'MARKETING_REPORTS':
                                    return Object.assign({}, child, {
                                        children: marketingReportItems
                                    });
                                default:
                                    return child;
                            }
                        });
                        const nav = (
                            <NavItem
                                href={item.href}
                                key={item.title}
                                title={item.title}
                                icon={item.icon}
                                subItems={children}
                            />
                        );
                        if (item.role) {
                            return (
                                <ShowForRole role={item.role} key={item.title}>
                                    {nav}
                                </ShowForRole>
                            );
                        }
                        return nav;
                    })}
                </List>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
        </Box>
    );

    return (
        <Drawer
            anchor="left"
            onClose={onNavClose}
            open={isNavOpen}
            variant="temporary"
            PaperProps={{
                sx: {
                    width: 256
                }
            }}
        >
            {content}
            <Hidden smUp>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 2
                    }}
                >
                    <Button
                        onClick={() => commands.logout()}
                        startIcon={<LogoutIcon />}
                        variant="contained"
                    >
                        Logout
                    </Button>
                </Box>
            </Hidden>
        </Drawer>
    );
};

DashboardSidebar.defaultProps = {
    onNavClose: () => {},
    isNavOpen: false
};
