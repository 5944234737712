import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 * @param {Date} month
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').GetAuthorsMonthlyDataResponse>>}
 */
const getMonthData = (month) => {
    return asyncFetch(
        'analytics/get-month-data',
        requestConfig({
            month
        })
    );
};

export { getMonthData };
