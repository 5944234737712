import { CacheMap } from 'smg-iq-utils';
import { asyncFetch } from 'src/api/utils/asyncFetch';

const cache = new CacheMap(1000 * 60 * 5);

const requestConfig = (data) => ({
    method: 'GET',
    data
});

/**
 *
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').PublicationDTO[]>}
 */
const getPublications = async () => {
    if (cache.has('publications')) {
        return cache.get('publications');
    }
    const publications = await asyncFetch(
        'analytics/publications',
        requestConfig({})
    );

    cache.set('publications', publications);
    return publications;
};

export { getPublications };
