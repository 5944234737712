import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {number} id
 * @returns {Promise<import('smg-iq').ApiCallResult<boolean>>}
 */
const cloneSavedReport = async (id) =>
    asyncFetch('analytics/clone-saved-report', requestConfig({ id }));

export { cloneSavedReport };
