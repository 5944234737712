import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 * @param {number} id
 * @param {Partial<Pick<import('@types/smg-iq/authors.analytics').Publication, 'domain' | 'googleAnalyticsViewId' | 'dataStartsFrom' | 'name'>>} fields
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').PublicationDTO>}
 */
const savePublication = async (id, fields) => {
    return await asyncFetch(
        'analytics/publication',
        requestConfig({
            id,
            ...fields
        })
    );
};

export { savePublication };
