import { FrontendConfig, EditorialAnalyticsConfig } from 'smg-iq-configs';
import {
    formatISODate,
    parseGeograpyFilter,
    geographyFilterToString
} from 'smg-iq-utils';

// eslint-disable-next-line import/no-extraneous-dependencies
import { ParamBinder } from 'react-url-params-model-binder';

import {
    reportTypes,
    showByTypes,
    orderDirections,
    orderByValues,
    granularities,
    allGroupByTypes,
    groupByTypes,
    DateIntervalWithPredefinedValuesWithSelectedTimeframeSchema
} from '../../schemas.js';

import { DATE_RANGE_PATTERN, DEFAULT_RANGE_VALUE } from '../common/index.js';
import { standardColumnGroups } from '../../standardColumnGroups.js';
import { getColumnFullName } from '../../columns/maps/common/getColumnFullName.js';
import { DefaultReportColumns } from '../../columns/maps/editorial/index.js';

const articleTypes = EditorialAnalyticsConfig.ArticleTypesDefaultValues;

const stringifyArticleTagsFitler = (array) => array.join(',');
const parseArticleTagsFilter = (str) => str.split(',');

/**
 *
 * @param {string} str
 * @returns {import('smg-iq').EditorialReportSpecification["range"]}
 */
const parseRangeFromString = (str) => {
    const decodedValue = decodeURIComponent(str);
    const match = decodedValue.match(DATE_RANGE_PATTERN);
    return match
        ? {
              from: new Date(match[1]),
              to: new Date(match[5])
          }
        : decodedValue;
};

const isPredefinedDateIntervalUrlPrameterValueValid = async (value) => {
    const valToValidate = parseRangeFromString(value);
    const { valid } =
        await DateIntervalWithPredefinedValuesWithSelectedTimeframeSchema.validate(
            valToValidate
        );

    return valid;
};

/**
 * @param {import('smg-iq').EditorialReportSpecification["range"]} interval
 * @returns {string}
 */
const predefinedIntervalToUrlParam = (interval) => {
    if (typeof interval === 'string') {
        return interval;
    }
    return `${formatISODate(interval.from)}+${formatISODate(interval.to)}`;
};

/**
 *
 * @param {import('smg-iq').EditorialReportSpecification} specification
 * @returns {Promise<boolean>}
 */
// const checkSpecificationConsistency = async (specification) => {
//     const { valid, errors } = await EditorialReportSpecificationSchema.validate(
//         specification
//     );
//     if (errors) {
//         console.log(errors);
//     }
//     return valid;
// };

/**
 * @type {import('react-url-params-model-binder').ParamBinder<import('smg-iq').EditorialReportSpecification, any>}
 */
const reportTypeBinder = new ParamBinder({
    urlParamName: 'type',
    defaultParamValue: reportTypes.editorial[0],
    setValueToModel: (spec, value) => {
        spec.type = value;
    },
    isUrlParamValueValid: async (value) =>
        reportTypes.editorial.includes(value),
    getUrlParamValue: (spec) => spec.type
    // isModelConsistent: checkSpecificationConsistency
});

/**
 * @type {import('react-url-params-model-binder').ParamBinder<import('smg-iq').EditorialReportSpecification, any>}
 */
const showByBinder = new ParamBinder({
    urlParamName: 'showBy',
    defaultParamValue: async (allParams) => {
        if (!(allParams instanceof URLSearchParams))
            return showByTypes.editorial[0];
        const spec = {};
        await reportTypeBinder.setToModel(
            spec,
            await reportTypeBinder.parseUrlParamValue(
                allParams.get('type'),
                allParams
            )
        );

        return showByTypes.editorial[0];
    },
    setValueToModel: (spec, value) => {
        spec.showBy = value;
    },
    isUrlParamValueValid: async (value, allParams) => {
        if (!(allParams instanceof URLSearchParams))
            return showByTypes.editorial.includes(value);
        const spec = {};
        await reportTypeBinder.setToModel(
            spec,
            await reportTypeBinder.parseUrlParamValue(
                allParams.get('type'),
                allParams
            )
        );
        return showByTypes.editorial.includes(value);
    },
    getUrlParamValue: (spec) => spec.showBy
    // isModelConsistent: checkSpecificationConsistency
});

/**
 * @type {import('react-url-params-model-binder').ParamBinder<import('smg-iq').EditorialReportSpecification, any>}
 */
const groupByBinder = new ParamBinder({
    urlParamName: 'groupBy',
    defaultParamValue: async (allParams) => {
        if (allParams instanceof URLSearchParams) {
            const spec = {};
            await reportTypeBinder.setToModel(
                spec,
                await reportTypeBinder.parseUrlParamValue(
                    allParams.get('type'),
                    allParams
                )
            );

            if (spec.type === 'metadata') {
                return groupByTypes.editorial[spec.type]?.[0] || '';
            }
        }
        return '';
    },
    setValueToModel: (model, value) => {
        if (!value || typeof value !== 'string') return;
        model.groupBy = value;
    },
    isUrlParamValueValid: async (value, allParams) => {
        if (!allGroupByTypes.editorial.includes(value)) return false;
        if (allParams instanceof URLSearchParams) {
            const spec = {};
            await reportTypeBinder.setToModel(
                spec,
                await reportTypeBinder.parseUrlParamValue(
                    allParams.get('type'),
                    allParams
                )
            );

            const reportType = spec.type;

            const possibleGroupBy = groupByTypes.editorial[reportType];
            if (!Array.isArray(possibleGroupBy)) return false;
            return possibleGroupBy.includes(value);
        }
        return true;
    },
    getUrlParamValue: (spec) => spec.groupBy
    // isModelConsistent: checkSpecificationConsistency
});

/**
 * @type {import('react-url-params-model-binder').ParamBinder<import('smg-iq').EditorialReportSpecification, any>[]}
 */
export const urlParamBindings = [
    reportTypeBinder,
    groupByBinder,
    new ParamBinder({
        urlParamName: 'range',
        defaultParamValue: DEFAULT_RANGE_VALUE,
        setValueToModel: (model, value) => {
            model.range = parseRangeFromString(value);
        },
        isUrlParamValueValid: isPredefinedDateIntervalUrlPrameterValueValid,
        getUrlParamValue: (spec) => predefinedIntervalToUrlParam(spec.range)
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'selection',
        defaultParamValue: 'last-month',
        setValueToModel: (model, value) => {
            model.selection = parseRangeFromString(value);
        },
        isUrlParamValueValid: isPredefinedDateIntervalUrlPrameterValueValid,
        getUrlParamValue: (spec) => predefinedIntervalToUrlParam(spec.selection)
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'page',
        defaultParamValue: '1',
        setValueToModel: (model, value) => {
            model.page = Number(value);
        },
        isUrlParamValueValid: async (value) => {
            const numRegex = /^\d+$/;
            return numRegex.test(value);
        },
        getUrlParamValue: (spec) => spec.page
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'pageSize',
        defaultParamValue: FrontendConfig.DataPageSizes[0].toString(),
        setValueToModel: (model, value) => {
            model.pageSize = Number(value);
        },
        isUrlParamValueValid: async (value) => {
            const numRegex = /^\d+$/;
            if (!numRegex.test(value)) {
                return false;
            }

            const numValue = Number(value);
            return FrontendConfig.DataPageSizes.includes(numValue);
        },
        getUrlParamValue: (spec) => spec.pageSize?.toString()
        // isModelConsistent: checkSpecificationConsistency
    }),
    showByBinder,
    new ParamBinder({
        urlParamName: 'articlePublishDate',
        defaultParamValue: 'last-month',
        /**
         *
         * @param {import('smg-iq').EditorialReportSpecification} model
         * @param {import('smg-iq').EditorialReportSpecification['range']} value
         */
        setValueToModel: (model, value) => {
            if (!model.filters) {
                model.filters = {};
            }

            if (!model.filters.article) {
                model.filters.article = {};
            }

            if (value === 'all-time') {
                delete model.filters.article.articlePublishDate;
                return;
            }

            model.filters.article.articlePublishDate = {
                operation: 'between',
                value: parseRangeFromString(value)
            };
        },
        isUrlParamValueValid: async (value, allParams) =>
            value === 'all-time' ||
            // eslint-disable-next-line no-return-await
            (await isPredefinedDateIntervalUrlPrameterValueValid(
                value,
                allParams
            )),
        getUrlParamValue: (spec) => {
            if (
                !spec.filters ||
                !spec.filters.article ||
                !spec.filters.article.articlePublishDate
            ) {
                return 'all-time';
            }

            return predefinedIntervalToUrlParam(
                spec.filters.article.articlePublishDate.value
            );
        }
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'columnGroups',
        defaultParamValue: standardColumnGroups.editorial
            .map((cg) => cg.id)
            .join(','),
        /**
         *
         * @param {import('smg-iq').EditorialReportSpecification} model
         * @param {import('smg-iq').EditorialReportSpecification['columnGroups']} value
         */
        setValueToModel: (model, value) => {
            model.columnGroups =
                typeof value === 'string' ? value.split(',') : value;
        },
        isUrlParamValueValid: async (value) => {
            const ids = value.split(',');
            return (
                ids.every(
                    (id) =>
                        standardColumnGroups.editorial.some(
                            (cg) => cg.id === id
                        ) || id.startsWith('id_')
                ) &&
                standardColumnGroups.editorial
                    .filter((cg) => cg.required)
                    .every((cg) => ids.includes(cg.id))
            );
        },
        getUrlParamValue: (spec) =>
            Array.isArray(spec.columnGroups)
                ? spec.columnGroups.join(',')
                : standardColumnGroups.editorial.map((cg) => cg.id).join(',')
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'orderDirection',
        defaultParamValue: orderDirections[0],
        setValueToModel: (spec, value) => {
            spec.orderDirection = value;
        },
        isUrlParamValueValid: async (value) => orderDirections.includes(value),
        getUrlParamValue: (spec) => spec.orderDirection
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'orderBy',
        defaultParamValue: async (allParams) => {
            if (!(allParams instanceof URLSearchParams)) {
                return orderByValues[0];
            }

            /**
             * @type {import('smg-iq').EditorialReportSpecification}
             */
            const spec = {};

            await reportTypeBinder.setToModel(
                spec,
                await reportTypeBinder.parseUrlParamValue(
                    allParams.get('type'),
                    allParams
                )
            );

            await showByBinder.setToModel(
                spec,
                await showByBinder.parseUrlParamValue(
                    allParams.get('showBy'),
                    allParams
                )
            );

            await groupByBinder.setToModel(
                spec,
                await groupByBinder.parseUrlParamValue(
                    allParams.get('groupBy'),
                    allParams
                )
            );

            const { type, showBy, groupBy = 'none' } = spec;

            if (
                DefaultReportColumns[type] &&
                DefaultReportColumns[type][showBy]
            ) {
                // if groupBy mapping is not defined, we use 'none' as fallback
                const columns = Array.isArray(
                    DefaultReportColumns[type][showBy][groupBy]
                )
                    ? DefaultReportColumns[type][showBy][groupBy]
                    : DefaultReportColumns[type][showBy].none;

                if (Array.isArray(columns)) {
                    const column = columns.find(
                        (c) => c.column.sortable && c.column.isDefaultSortColumn
                    );
                    return column
                        ? getColumnFullName(column)
                        : orderByValues[0];
                }
            }
            return orderByValues[0];
        },
        setValueToModel: (spec, value) => {
            spec.orderBy = value;
        },
        isUrlParamValueValid: async () => true,
        getUrlParamValue: (spec) => spec.orderBy
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'granularity',
        defaultParamValue: granularities[2],
        setValueToModel: (spec, value) => {
            spec.granularity = value;
        },
        isUrlParamValueValid: async (value) => granularities.includes(value),
        getUrlParamValue: (spec) => spec.granularity
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'publicationId',
        defaultParamValue: '',
        setValueToModel: (model, value) => {
            if (!value) return;
            if (!model.filters) {
                model.filters = {};
            }
            if (!model.filters.article) {
                model.filters.article = {};
            }

            model.filters.article.publicationId = {
                operation: 'equals',
                value: Number(value)
            };
        },
        isUrlParamValueValid: async (value) => {
            const numRegex = /^\d+$/;
            return numRegex.test(value);
        },
        getUrlParamValue: (spec) =>
            spec.filters?.article?.publicationId?.value || ''
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'authors',
        defaultParamValue: '',
        /**
         *
         * @param {import('smg-iq').EditorialReportSpecification} model
         * @param {string[]} value
         */
        setValueToModel: (model, value) => {
            if (!value || typeof value !== 'string') return;
            if (!model.filters) {
                model.filters = {};
            }
            if (!model.filters.author) {
                model.filters.author = {};
            }
            model.filters.author.id = {
                operation: 'in',
                value: value.split(',')
            };
        },
        isUrlParamValueValid: async () => true,
        getUrlParamValue: (spec) =>
            Array.isArray(spec?.filters?.author?.id?.value)
                ? spec.filters.author.id.value.join(',')
                : ''
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'articleAuthorType',
        defaultParamValue: '',
        /**
         *
         * @param {import('smg-iq').EditorialReportSpecification} model
         * @param {string[]} value
         */
        setValueToModel: (model, value) => {
            if (!value || typeof value !== 'string') return;
            if (!model.filters) {
                model.filters = {};
            }
            if (!model.filters.article) {
                model.filters.article = {};
            }
            model.filters.article.articleAuthorType = {
                operation: 'in',
                value: value.split(',')
            };
        },
        isUrlParamValueValid: async () => true,
        getUrlParamValue: (spec) =>
            Array.isArray(spec?.filters?.article?.articleAuthorType?.value)
                ? spec.filters.article.articleAuthorType.value.join(',')
                : ''
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'articleIncludeNotCanonical',
        defaultParamValue: '',
        setValueToModel: (model, value) => {
            if (!value || typeof value !== 'string') return;
            if (!model.filters) {
                model.filters = {};
            }
            if (!model.filters.article) {
                model.filters.article = {};
            }
            model.filters.article.includeNonCanonical = value === 'true';
        },
        isUrlParamValueValid: async () => true,
        getUrlParamValue: (spec) =>
            spec?.filters?.article?.includeNonCanonical ? 'true' : ''
    }),
    new ParamBinder({
        urlParamName: 'articleTitle',
        defaultParamValue: '',
        setValueToModel: (model, value) => {
            if (!value || typeof value !== 'string') return;
            const decodedValue = decodeURIComponent(value);

            const extractedItems = decodedValue.split('||').map((v) => ({
                value: v.substring(1),
                type: v[0] === 'l' ? 'like' : 'equals'
            }));

            if (!model.filters) {
                model.filters = {};
            }

            if (!model.filters.article) {
                model.filters.article = {};
            }

            model.filters.article.articleItemIdOrTitle = extractedItems.map(
                (item) =>
                    item.type === 'like'
                        ? {
                              field: 'title',
                              operation: 'like',
                              value: item.value
                          }
                        : {
                              field: 'articleItemId',
                              operation: 'equals',
                              value: item.value
                          }
            );
        },
        isUrlParamValueValid: async () => true,
        getUrlParamValue: (spec) =>
            Array.isArray(spec?.filters?.article?.articleItemIdOrTitle)
                ? spec.filters.article.articleItemIdOrTitle
                      .map((item) =>
                          item.field === 'title'
                              ? `l${item.value}`
                              : `e${item.value}`
                      )
                      .join('||')
                : ''
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'geography',
        defaultParamValue: 'United States;Canada',
        setValueToModel: (model, value) => {
            if (!value || typeof value !== 'string') return;
            const parsed = parseGeograpyFilter(value);
            if (!model.filters) {
                model.filters = {};
            }
            model.filters.geography = parsed;
        },
        isUrlParamValueValid: async () => true,
        getUrlParamValue: (spec) =>
            Array.isArray(spec?.filters?.geography)
                ? geographyFilterToString(spec.filters.geography)
                : ''
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'articleTags',
        defaultParamValue: '',
        setValueToModel: (model, value) => {
            if (!value || typeof value !== 'string') return;
            const parsed = parseArticleTagsFilter(value);
            if (parsed.length === 0) return;
            if (!model.filters) {
                model.filters = {};
            }
            if (!model.filters.article) {
                model.filters.article = {};
            }

            model.filters.article.articleTags = {
                operation: 'has_any',
                value: parsed
            };
        },
        isUrlParamValueValid: async () => true,
        getUrlParamValue: (spec) =>
            Array.isArray(spec?.filters?.article?.articleTags?.value)
                ? stringifyArticleTagsFitler(
                      spec.filters.article.articleTags.value
                  )
                : ''
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'articleCategory',
        defaultParamValue: '',
        /**
         *
         * @param {import('smg-iq').EditorialReportSpecification} model
         * @param {string[]} value
         */
        setValueToModel: (model, value) => {
            if (!value || typeof value !== 'string') return;
            if (!model.filters) {
                model.filters = {};
            }
            if (!model.filters.article) {
                model.filters.article = {};
            }
            model.filters.article.articleCategory = {
                operation: 'in',
                value: value.split(',')
            };
        },
        isUrlParamValueValid: async () => true,
        getUrlParamValue: (spec) =>
            Array.isArray(spec?.filters?.article?.articleCategory?.value)
                ? spec.filters.article.articleCategory.value.join(',')
                : ''
        // isModelConsistent: checkSpecificationConsistency
    }),
    new ParamBinder({
        urlParamName: 'articleType',
        defaultParamValue: Object.keys(articleTypes)
            .filter((k) => articleTypes[k])
            .sort()
            .join(','),
        setValueToModel: (model, value) => {
            if (!value || typeof value !== 'string') return;
            if (!model.filters) {
                model.filters = {};
            }
            if (!model.filters.article) {
                model.filters.article = {};
            }
            model.filters.article.articleType = {
                operation: 'in',
                value: value.split(',')
            };
        },
        isUrlParamValueValid: async (value) => {
            const splitted = value.split(',');
            return splitted.every((v) => Object.keys(articleTypes).includes(v));
        },
        getUrlParamValue: (spec) =>
            Array.isArray(spec?.filters?.article?.articleType?.value)
                ? spec.filters.article.articleType.value.join(',')
                : ''
        // isModelConsistent: checkSpecificationConsistency
    })
];
