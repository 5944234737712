import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = {
    method: 'GET'
};

/**
 * @returns {Promise<import('smg-iq').ApiCallResult<Array<{month: Date, activeAuthorsCount: number, articlesCount: number}>>>}
 */
const getMonthsWithArticles = () =>
    asyncFetch('analytics/months-with-articles', requestConfig);

export { getMonthsWithArticles };
