/**
 *
 * @type {(str: string) => import('smg-iq').EditorialReportSpecification['filters']['geography'] }>}
 */
export const parseGeograpyFilter = (str) => {
    if (typeof str !== 'string' || !str) return [];
    const countries = str.split(';');

    return countries.reduce((acc, curr) => {
        const [countryCode, regionsStr] = curr.split(':');
        const item = {
            countryCode
        };
        if (regionsStr && regionsStr.length > 0) {
            item.regions = regionsStr.split('^');
        }
        acc.push(item);
        return acc;
    }, []);
};

/**
 *
 * @type {(filter: import('smg-iq').EditorialReportSpecification['filters']['geography']) => string}
 */
export const geographyFilterToString = (filter) => {
    if (!Array.isArray(filter)) return '';
    return filter
        .map((item) =>
            Array.isArray(item.regions)
                ? `${item.countryCode}:${item.regions.join('^')}`
                : item.countryCode
        )
        .join(';');
};
