export const standardColumnGroups = {
    editorial: [
        {
            id: 'general',
            name: 'General',
            required: true
        },
        {
            id: 'financial',
            name: 'Financial'
        }
    ],

    marketing: [
        {
            id: 'general',
            name: 'General',
            required: true
        }
    ]
};
