import React from 'react';
import { AuthConfig } from 'smg-iq-configs';

import { logout } from 'src/api/requests/auth';
import { deleteCookie } from '../utils/authUtils';

/**
 * @typedef {Object} AuthContextUser
 * @prop {String} firstName
 * @prop {String} lastName
 * @prop {String} picture
 * @prop {Array<String>} roles
 * @prop {String} email
 */

const commands = {
    logout: async () => {
        await logout();
        deleteCookie(AuthConfig.CookieName);
        window.location = '/';
    }
};

const defaultAuthState = {
    authenticated: false,
    /**
     * @type {AuthContextUser}
     */
    user: null,
    commands
};
const AuthContext = React.createContext(defaultAuthState);

export { defaultAuthState, AuthContext, commands };
