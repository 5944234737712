import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').SearchLogsRequest} spec
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').EditorialAnalyticsLogRecordDTO[]>>}
 */
const searchLogs = async (spec) =>
    asyncFetch('analytics/search-logs', requestConfig(spec));

export { searchLogs };
