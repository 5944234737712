import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').SearchAttributesRequest} spec
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').SearchEventsResponse>>}
 */
export const searchEvents = async (spec) =>
    asyncFetch('analytics/search-events', requestConfig(spec));
