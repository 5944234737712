import PropTypes from 'prop-types';
import { useAuthContext } from 'src/hooks/useContext';
import { UserRolesConfig } from 'smg-iq-configs';

/**
 *
 * @param {{role: string | string[]}} props
 * @returns
 */
const ShowForRole = ({ children, role }) => {
    let targetRole = role;
    if (!targetRole) {
        targetRole = UserRolesConfig.roles.ADMIN;
    }
    const {
        user: { roles },
        authenticated
    } = useAuthContext();
    if (
        authenticated &&
        (roles.includes(UserRolesConfig.roles.ADMIN) ||
            (typeof role === 'string' && roles.includes(role)) ||
            (Array.isArray(role) && role.find((r) => roles.includes(r))))
    ) {
        return <>{children}</>;
    }
    return <></>;
};

export { ShowForRole };

ShowForRole.propTypes = {
    children: PropTypes.any,
    role: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string)
    ])
};
