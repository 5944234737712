import { useState, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material';
import { DashboardNavbar } from '../DashboardNavbar';
import { DashboardSidebar } from '../DashboardSidebar';
import { LongLoading } from '../LongLoading';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
}));

const DashboardLayoutContainer = experimentalStyled('div')({
    display: 'flex',
    flex: '1',
    overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
    flex: '1',
    height: '100%',
    overflow: 'auto',
    position: 'relative'
});

const DashboardLayout = () => {
    const [isNavOpen, setNavOpen] = useState(false);

    const handleNavClose = useCallback(() => setNavOpen(false), []);
    const handleOnNavOpen = useCallback(() => setNavOpen(true), []);

    return (
        <DashboardLayoutRoot>
            <DashboardNavbar onNavOpen={handleOnNavOpen} />
            <DashboardSidebar
                onNavClose={handleNavClose}
                isNavOpen={isNavOpen}
            />
            <DashboardLayoutWrapper>
                <DashboardLayoutContainer>
                    <DashboardLayoutContent>
                        <LongLoading>
                            <Outlet />
                        </LongLoading>
                    </DashboardLayoutContent>
                </DashboardLayoutContainer>
            </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
    );
};

export { DashboardLayout };
