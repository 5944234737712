/**
 * Formats `date` as YYYY-MM-DD string
 * @param {Date} date
 * @param {any} isLastDayOfTheMonth - not used
 * @returns {string}
 */
export const formatISODate = (date) => {
    const d = new Date(date);
    const year = d.getUTCFullYear();
    let month = `${d.getUTCMonth() + 1}`;
    let day = `${d.getUTCDate()}`;

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
};
