import { FrontendConfig } from 'smg-iq-configs';
import { isValidEmail } from 'smg-iq-utils';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Schemas as SchedulerSchemas } from '@cleverbrush/scheduler';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
    array,
    boolean,
    date,
    number,
    object,
    string,
    union
} from '@cleverbrush/schema';

import { parsePredefinedInterval } from './predefinedDateIntervals.js';
import { standardColumnGroups } from './standardColumnGroups.js';

export const showByTypes = {
    editorial: ['visitors', 'views', 'social_referrals', 'search_referrals'],
    marketing: ['visitors', 'views', 'newVisitors']
};

export const showByNames = {
    editorial: {
        visitors: 'Users',
        views: 'Page Views',
        social_referrals: 'Social Referrals',
        search_referrals: 'Search Referrals'
    },
    marketing: {
        visitors: 'Users',
        views: 'Page Views',
        newVisitors: 'New Users'
    }
};

export const CUSTOM_COLUMN_GROUP_ID_REGEX = /^id_(\d+)$/;

export const buildCustomColumnGroupId = (id) => `id_${id}`;

/**
 *
 * @param {string} id
 * @returns {number}
 */
export const parseCustomColumnGroupId = (id) => {
    const match = id.match(CUSTOM_COLUMN_GROUP_ID_REGEX);
    if (!match) throw new Error(`Invalid custom column group id: ${id}`);
    return parseInt(match[1], 10);
};

/**
 *
 * @param {string} id
 * @returns {boolean}
 */
export const isCustomColumnGroup = (id) =>
    CUSTOM_COLUMN_GROUP_ID_REGEX.test(id);

export const groupByTypes = {
    editorial: {
        author: ['authorType'],
        content: ['authorType', 'articleCategory', 'articleType'],
        metadata: ['referrers', 'campaigns', 'visitors']
    },
    marketing: {
        campaign: ['url', 'utm_source', 'utm_content', 'source_medium'],
        content: ['url', 'utm_source', 'utm_campaign', 'source_medium'],
        url: ['utm_source', 'utm_content', 'utm_campaign', 'source_medium']
    }
};

export const groupByNames = {
    editorial: {
        authorType: 'Author Type',
        articleCategory: 'Article Category',
        articleType: 'Article Type',
        referrers: 'Referrers',
        campaigns: 'Campaigns',
        visitors: 'Visitors'
    },
    marketing: {
        url: 'URL',
        utm_source: 'UTM Source',
        utm_content: 'UTM Content',
        utm_campaign: 'UTM Campaign',
        source_medium: 'Source/Medium'
    }
};

export const reportTypes = {
    editorial: ['author', 'content', 'metadata'],
    marketing: ['campaign', 'content', 'url']
};

export const reportTypeNames = {
    editorial: {
        author: 'Author',
        content: 'Content',
        metadata: 'Metadata'
    },
    marketing: {
        campaign: 'Campaign',
        content: 'Content',
        url: 'URL'
    }
};

export const orderDirections = ['desc', 'asc'];
export const orderByValues = [
    'general.users',
    'general.usersPerArticle',
    'general.sessions',
    'general.sessionsPerArticle',
    'general.pageViews',
    'general.articlesCount',
    'general.pageViewsPerArticle',
    'general.uniqArticlesCount',
    'general.avgSessionDuration',
    'general.totalSessionsDuration',
    'general.socialReferrals',
    'general.socialReferralsPerArticle',
    'general.searchReferrals',
    'general.searchReferralsPerArticle',
    'financial.costPerUser',
    'financial.costPerSession',
    'financial.costPerArticle',
    'financial.costPerPageView',
    'financial.costPerSocialReferral',
    'financial.costPerSearchReferral'
];
export const orderDirectionNames = {
    asc: 'Ascending',
    desc: 'Descending'
};
export const granularities = ['day', 'week', 'month', 'quarter', 'year'];
export const granularityNames = {
    day: 'Daily',
    week: 'Weekly',
    month: 'Monthly',
    quarter: 'Quarterly',
    year: 'Yearly'
};

const scheduleSchemaOptions =
    SchedulerSchemas.ScheduleSchema.introspect().options;

/**
 * @type {('minute' | 'day' | 'week' | 'month' | 'year')[]}
 */
export const scheduleTypes = scheduleSchemaOptions.map(
    (option) => option.introspect().properties.every.introspect().equalsTo
);

/**
 * @type {Record<'minute' | 'day' | 'week' | 'month' | 'year', string>}
 */
export const scheduleTypeNames = {
    minute: 'Minutes',
    day: 'Days',
    week: 'Weeks',
    month: 'Months',
    year: 'Years'
};

/**
 * @type {Record<'minute' | 'day' | 'week' | 'month' | 'year', string>}
 */
export const scheduleTypeNamesAdj = {
    minute: 'Minutely',
    day: 'Daily',
    week: 'Weekly',
    month: 'Monthly',
    year: 'Yearly'
};

const dateIntervalPreprocessor = (value) => {
    if (typeof value === 'undefined') return value;
    if (typeof value === 'string') {
        try {
            return parsePredefinedInterval(value);
        } catch (e) {
            return value;
        }
    }
    return value;
};

export const DateIntervalSchema = object({
    from: date().acceptJsonString(),
    to: date().acceptJsonString()
})
    .addValidator((value) => {
        if (value.from <= value.to) {
            return {
                valid: true
            };
        }
        return {
            valid: false,
            errors: [{ message: 'from must be <= than to' }]
        };
    })
    .addPreprocessor(dateIntervalPreprocessor);

export const PredefinedDateIntervalValuesSchema = union(string('this-week'))
    .or(string('this-month'))
    .or(string('last-month'))
    .or(string('this-quarter'))
    .or(string('last-quarter'))
    .or(string('this-year'))
    .or(string('last-year'))
    .addValidator((value) => {
        if (typeof value !== 'string')
            return {
                valid: false,
                errors: [{ message: 'should be a string' }]
            };
        const found = [
            'last-week',
            'last-month',
            'last-quarter',
            'last-year'
        ].find((interval) => value.startsWith(interval));
        if (found) {
            return {
                valid: true
            };
        }
        return {
            valid: false,
            errors: [{ message: `unknown predefined interval ${value}` }]
        };
    });

export const DateIntervalWithPredefinedValuesSchema = union(
    DateIntervalSchema
).or(PredefinedDateIntervalValuesSchema);

export const DateIntervalWithPredefinedValuesWithSelectedTimeframeSchema =
    union(DateIntervalWithPredefinedValuesSchema).or(
        string('selected-timeframe')
    );

const StringEqualsFilterConditionSchema = object({
    operation: string('equals'),
    value: string().minLength(1)
});

const StringInFilterConditionSchema = object({
    operation: string('in'),
    value: array().of(string().minLength(1)).minLength(1).maxLength(100)
});

const LikeFilterConditionSchema = object({
    operation: string('like'),
    value: string().minLength(1)
});

const StringFilterConditionSchema = union(StringEqualsFilterConditionSchema)
    .or(StringInFilterConditionSchema)
    .or(LikeFilterConditionSchema);

const AuthorFilterSchema = object({
    id: StringFilterConditionSchema.optional(),
    fullName: StringFilterConditionSchema.optional()
});

const DateInFilterConditionSchema = object({
    operation: string('in'),
    value: array().of(date().acceptJsonString()).minLength(1).maxLength(10)
});

const DateEqualsFilterConditionSchema = object({
    operation: string('equals'),
    value: date().acceptJsonString()
});

const DateBetweenFilterConditionSchema = object({
    operation: string('between'),
    value: DateIntervalWithPredefinedValuesWithSelectedTimeframeSchema
});

const DateGreaterThanFilterConditionSchema = object({
    operation: string('greater_than'),
    value: date().acceptJsonString()
});

const DateLessThanFilterConditionSchema = object({
    operation: string('less_than'),
    value: date().acceptJsonString()
});

const DateFilterConditionSchema = union(DateInFilterConditionSchema)
    .or(DateEqualsFilterConditionSchema)
    .or(DateBetweenFilterConditionSchema)
    .or(DateGreaterThanFilterConditionSchema)
    .or(DateLessThanFilterConditionSchema);

const StringArrayFilterConditionSchema = object({
    operation: union(string('has_any')).or(string('has_all')),
    value: union(string().minLength(1))
        .or(number())
        .or(array().of(string().minLength(1)).minLength(1).maxLength(100))
});

const NumberEqualsFilterConditionSchema = object({
    operation: string('equals'),
    value: number()
});

const NumberInFilterConditionSchema = object({
    operation: string('in'),
    value: array().of(number().min(0)).minLength(1).maxLength(100)
});

const NumberIdFilterConditionSchema = union(
    NumberEqualsFilterConditionSchema
).or(NumberInFilterConditionSchema);

const ArticleItemIdOrTitleFilterItemIdPartSchema = object({
    field: string('articleItemId'),
    operation: string('equals'),
    value: string().minLength(1)
});

const ArticleItemIdOrTitleFilterItemTitlePartSchema = object({
    field: string('title'),
    operation: string('like'),
    value: string().minLength(1)
});

const ArticleItemIdOrTitleFilterItemSchema = union(
    ArticleItemIdOrTitleFilterItemIdPartSchema
).or(ArticleItemIdOrTitleFilterItemTitlePartSchema);

const AttributeFilterItemSchema = union(
    object({
        operation: string('equals'),
        value: string().minLength(1)
    })
).or(
    object({
        operation: union(string('like')).or(string('not_like')),
        value: string().minLength(1)
    })
);

const AttributeFilterSimpleSchema = array()
    .of(AttributeFilterItemSchema)
    .minLength(1)
    .maxLength(100);

const AttributeFilterExpressionEndSchema = object({
    operation: union(string('and')).or(string('or')),
    children: AttributeFilterSimpleSchema
});

const AttributeFilterExpressionSchema = AttributeFilterExpressionEndSchema.omit(
    'children'
).addProps({
    children: array()
        .of(
            union(AttributeFilterSimpleSchema).or(
                AttributeFilterExpressionEndSchema
            )
        )
        .minLength(1)
        .maxLength(100)
});

export const AttributeFilterSchema = union(AttributeFilterSimpleSchema).or(
    AttributeFilterExpressionSchema
);

const GeographyFilterItemSchema = object({
    countryCode: string().maxLength(200),
    regions: array()
        .of(string().minLength(1))
        .minLength(1)
        .maxLength(300)
        .optional()
});

export const GeographyFilterSchema = array()
    .of(GeographyFilterItemSchema)
    .minLength(1)
    .maxLength(20);

const ArticleFilterSchema = object({
    articleCategory: StringFilterConditionSchema.optional(),
    articleType: StringFilterConditionSchema.optional(),
    articleAuthorType: StringFilterConditionSchema.optional(),
    articleTitle: StringFilterConditionSchema.optional(),
    articleTags: StringArrayFilterConditionSchema.optional(),
    articlePublishDate: DateFilterConditionSchema.optional(),
    publicationId: NumberIdFilterConditionSchema.optional(),
    includeNonCanonical: boolean().optional(),
    articleItemIdOrTitle: array()
        .of(ArticleItemIdOrTitleFilterItemSchema)
        .minLength(1)
        .maxLength(100)
        .optional()
});

export const EditorialReportTypeSchema = union(string('author'))
    .or(string('content'))
    .or(string('metadata'));

export const MarketingReportTypeSchema = union(string('campaign'))
    .or(string('content'))
    .or(string('url'));

export const ReportGranularitySchema = union(string('day'))
    .or(string('week'))
    .or(string('month'))
    .or(string('quarter'))
    .or(string('year'));

export const EditorialReportShowBySchema = union(string('visitors'))
    .or(string('views'))
    .or(string('social_referrals'))
    .or(string('search_referrals'));

export const EditorialReportGroupBySchema = union(string('authorType'))
    .or(string('articleCategory'))
    .or(string('articleType'))
    .or(string('referrers'))
    .or(string('campaigns'))
    .or(string('visitors'));

export const MarketingReportGroupBySchema = union(string('url'))
    .or(string('utm_source'))
    .or(string('utm_content'))
    .or(string('utm_campaign'))
    .or(string('source_medium'));

export const allGroupByTypes = Object.keys(groupByTypes).reduce((acc, key) => {
    acc[key] = Object.keys(groupByTypes[key]).reduce((acc2, key2) => {
        groupByTypes[key][key2].forEach((el) => {
            if (!acc2.includes(el)) acc2.push(el);
        });
        return acc2;
    }, []);
    return acc;
}, {});

export const OrderDirectionSchema = union(string('asc')).or(string('desc'));

const EditorialMetricsSchema = array()
    .of(
        union(string('articlesPublished'))
            .or(string('searchReferrals'))
            .or(string('socialReferrals'))
            .or(string('visitorsPerArticle'))
            .or(string('sessionsPerArticle'))
            .or(string('views'))
            .or(string('viewsPerArticle'))
            .or(string('visitors'))
            .or(string('newVisitors'))
            .or(string('avgBounceRate'))
            .or(string('avgSessionDuration'))
    )
    .minLength(1)
    .addValidator((value) =>
        value
            .map((v) => value.filter((k) => k === v).length)
            .every((l) => l === 1)
            ? {
                  valid: true
              }
            : {
                  valid: false,
                  errors: [
                      {
                          message: 'metrics should not contain duplicates'
                      }
                  ]
              }
    );

const MarketingMetricsSchema = array()
    .of(
        union(string('views'))
            .or(string('visitors'))
            .or(string('newVisitors'))
            .or(string('events'))
    )
    .minLength(1)
    .addValidator((value) =>
        value
            .map((v) => value.filter((k) => k === v).length)
            .every((l) => l === 1)
            ? {
                  valid: true
              }
            : {
                  valid: false,
                  errors: [
                      {
                          message: 'metrics should not contain duplicates'
                      }
                  ]
              }
    );

/**
 * NOTE: just keep it for type resolving
 * @type {`id_${number}`}
 */
const columnGroupLiteral = '';

/**
 * NOTE: just keep it for type resolving
 * @type {never}
 */
const never = '';

export const DefaultEditorialReportColumnsSchema = union(
    string('default.users')
)
    .or(string('default.usersPerArticle'))
    .or(string('default.sessions'))
    .or(string('default.sessionsPerArticle'))
    .or(string('default.pageViews'))
    .or(string('default.articlesCount'))
    .or(string('default.pageViewsPerArticle'))
    .or(string('default.uniqArticlesCount'))
    .or(string('default.avgSessionDuration'))
    .or(string('default.totalSessionsDuration'))
    .or(string('default.socialReferrals'))
    .or(string('default.socialReferralsPerArticle'))
    .or(string('default.searchReferrals'))
    .or(string('default.searchReferralsPerArticle'))
    .or(string('default.costPerUser'))
    .or(string('default.costPerSession'))
    .or(string('default.costPerArticle'))
    .or(string('default.costPerPageView'))
    .or(string('default.costPerSocialReferral'))
    .or(string('default.costPerSearchReferral'));

export const DefaultMarketingReportColumnsSchema = union(
    string('default.users')
)
    .or(string('default.sessions'))
    .or(string('default.pageViews'))
    .or(string('default.avgSessionDuration'))
    .or(string('default.totalSessionsDuration'))
    .or(string('default.sessionCampaign'));

const ReportIdSchema = string().minLength(5);
const PageSchema = number().min(1);
const PageSizeSchema = number().min(1).max(200);

const MarketingReportShowBySchema = union(string('visitors'))
    .or(string('views'))
    .or(string('newVisitors'));

const ReportSpecificationFiltersSchema = object({
    url: AttributeFilterSchema.optional(),
    pageReferrer: AttributeFilterSchema.optional(),
    event: AttributeFilterSchema.optional(),
    utm: object({
        source: AttributeFilterSchema.optional(),
        medium: AttributeFilterSchema.optional(),
        content: AttributeFilterSchema.optional(),
        campaign: AttributeFilterSchema.optional(),
        term: AttributeFilterSchema.optional()
    })
        .makeAllPropsOptional()
        .optional(),
    author: AuthorFilterSchema,
    article: ArticleFilterSchema,
    geography: GeographyFilterSchema
}).makeAllPropsOptional();

export const MarketingReportSpecificationSchema = object({
    type: MarketingReportTypeSchema,
    reportId: ReportIdSchema.optional(),
    page: PageSchema.optional(),
    pageSize: PageSizeSchema.optional(),
    range: DateIntervalWithPredefinedValuesSchema,
    selection: DateIntervalWithPredefinedValuesSchema,
    granularity: ReportGranularitySchema,
    groupBy: MarketingReportGroupBySchema.optional(),
    showBy: MarketingReportShowBySchema,
    orderBy: union(DefaultMarketingReportColumnsSchema).or(
        string().hasType(never)
    ),
    orderDirection: OrderDirectionSchema,
    metrics: MarketingMetricsSchema,
    columnGroups: array()
        .of(
            union(string('general')).or(
                string()
                    .matches(CUSTOM_COLUMN_GROUP_ID_REGEX)
                    .hasType(columnGroupLiteral)
            )
        )
        .minLength(1)
        .addPreprocessor((value) => {
            if (value) return value;
            return standardColumnGroups.marketing.map((cg) => cg.id);
        })
        .addValidator((value) => {
            if (Array.isArray(value)) {
                if (!value.includes('general')) {
                    return {
                        valid: false,
                        errors: [
                            { message: "'general' column group is required" }
                        ]
                    };
                }
                return {
                    valid: true
                };
            }
            // do nothing and let schema validation to reject this case later
            return {
                valid: true
            };
        })
        .optional(),
    filters: ReportSpecificationFiltersSchema.optional()
}).addPreprocessor((value) => {
    if (typeof value !== 'object' || value === null) return value;

    if (value.selection?.from < value.range?.from) {
        value.range.from = value.selection.from;
    }
    if (value.selection?.to > value.range?.to) {
        value.range.to = value.selection.to;
    }

    if (
        value.filters?.article?.articlePublishDate?.operation === 'between' &&
        value.filters?.article?.articlePublishDate?.value ===
            'selected-timeframe'
    ) {
        value.filters.article.articlePublishDate.value =
            typeof value.selection === 'string'
                ? parsePredefinedInterval(value.selection)
                : {
                      from: value.selection.from,
                      to: value.selection.to
                  };
    }
    if (!value.metrics) {
        value.metrics = MarketingMetricsSchema.introspect()
            .elementSchema.introspect()
            .options.map((v) => v.introspect().equalsTo);
    }

    return value;
});

export const EditorialReportSpecificationSchema = object({
    type: EditorialReportTypeSchema,
    reportId: ReportIdSchema.optional(),
    page: PageSchema.optional(),
    pageSize: PageSizeSchema.optional(),
    range: DateIntervalWithPredefinedValuesSchema,
    selection: DateIntervalWithPredefinedValuesSchema,
    columnGroups: array()
        .of(
            union(string('general'))
                .or(string('financial'))
                .or(
                    string()
                        .matches(CUSTOM_COLUMN_GROUP_ID_REGEX)
                        .hasType(columnGroupLiteral)
                )
        )
        .minLength(1)
        .addPreprocessor((value) => {
            if (value) return value;
            return standardColumnGroups.editorial.map((cg) => cg.id);
        })
        .addValidator((value) => {
            if (Array.isArray(value)) {
                if (!value.includes('general')) {
                    return {
                        valid: false,
                        errors: [
                            { message: "'general' column group is required" }
                        ]
                    };
                }
                return {
                    valid: true
                };
            }
            // do nothing and let schema validation to reject this case later
            return {
                valid: true
            };
        })
        .optional(),
    granularity: ReportGranularitySchema,
    showBy: EditorialReportShowBySchema,
    groupBy: EditorialReportGroupBySchema.optional(),
    orderBy: union(DefaultEditorialReportColumnsSchema).or(
        string().hasType(never)
    ),
    orderDirection: OrderDirectionSchema,
    metrics: EditorialMetricsSchema,
    filters: ReportSpecificationFiltersSchema.optional()
})
    .addPreprocessor((value) => {
        if (typeof value !== 'object' || value === null) return value;

        if (value.selection?.from < value.range?.from) {
            value.range.from = value.selection.from;
        }
        if (value.selection?.to > value.range?.to) {
            value.range.to = value.selection.to;
        }

        if (
            value.filters?.article?.articlePublishDate?.operation ===
                'between' &&
            value.filters?.article?.articlePublishDate?.value ===
                'selected-timeframe'
        ) {
            value.filters.article.articlePublishDate.value =
                typeof value.selection === 'string'
                    ? parsePredefinedInterval(value.selection)
                    : {
                          from: value.selection.from,
                          to: value.selection.to
                      };
        }

        if (!value.metrics) {
            value.metrics = [
                'avgBounceRate',
                'avgSessionDuration',
                'articlesPublished',
                'newVisitors',
                'searchReferrals',
                'socialReferrals',
                'views',
                'viewsPerArticle',
                'visitors',
                'visitorsPerArticle',
                'sessionsPerArticle'
            ];
        }

        return value;
    })
    .addValidator((value) => {
        if (
            !value.groupBy ||
            groupByTypes.editorial[value.type].includes(value.groupBy)
        ) {
            return { valid: true };
        }
        return {
            valid: false,
            errors: [{ message: 'invalid groupBy value' }]
        };
    });

export const AnalyticsReportTypeSchema = union(string('editorial')).or(
    string('marketing')
);

export const SaveReportRequestSchema = object({
    specificationType: AnalyticsReportTypeSchema,
    isGlobal: boolean().optional(),
    name: string().minLength(3).maxLength(200),
    specification: union(EditorialReportSpecificationSchema).or(
        MarketingReportSpecificationSchema
    ),
    placeAfter: number().isInteger().min(1).optional(),
    schedule: SchedulerSchemas.ScheduleSchema.optional(),
    recipients: array()
        .of(
            string()
                .addValidator((val) => {
                    if (isValidEmail(val)) {
                        return { valid: true };
                    }
                    return {
                        valid: false,
                        errors: [{ message: `${val} is not a valid email` }]
                    };
                })
                .minLength(1)
                .maxLength(100)
        )
        .addValidator((val) => {
            if (typeof val === 'undefined') {
                return {
                    valid: true
                };
            }
            if (!Array.isArray(val)) {
                return {
                    valid: false,
                    errors: [{ message: 'not an array' }]
                };
            }
            const map = {};
            const lowerCased = val.map((v) => v.toLowerCase());
            for (let i = 0; i < lowerCased.length; i++) {
                if (lowerCased[i] in map) {
                    return {
                        valid: false,
                        errors: [{ message: 'no duplicates allowed' }]
                    };
                }
                map[lowerCased[i]] = true;
            }
            return { valid: true };
        })
        .optional()
}).addValidator((obj) => {
    if (
        ('schedule' in obj && 'recipients' in obj) ||
        (!('schedule' in obj) && !('recipients' in obj))
    ) {
        return { valid: true };
    }
    return {
        valid: false,
        errors: [{ message: 'schedule must appear along with recipients' }]
    };
});

export const UpdateReportRequestSchema =
    SaveReportRequestSchema.makeAllPropsOptional().addProps({
        id: number().min(1)
    });

export const SavedReportSchema = SaveReportRequestSchema.addProps({
    id: number().min(1)
});

export const SaveIndividualReportRequestSchema = object({
    specificationType: AnalyticsReportTypeSchema,
    name: string().minLength(2).maxLength(300),
    emailSubject: string().minLength(2).maxLength(500),
    publicationId: number().min(0),
    specification: EditorialReportSpecificationSchema,
    schedule: SchedulerSchemas.ScheduleSchema,
    maxResults: number().min(1),
    recipients: array().of(string().minLength(1)).maxLength(100).optional()
});

export const UpdateIndividualReportRequestSchema =
    SaveIndividualReportRequestSchema.makeAllPropsOptional().addProps({
        id: number().min(0)
    });

export const IndividualReportSchema =
    SaveIndividualReportRequestSchema.addProps({
        id: number().min(0)
    }).acceptUnknownProps();

export const GetIndividualReportsForPublicationRequestSchema = object({
    publicationId: number().min(1)
});

export const GetIndividualReportsRecipientsRequestSchema = object({
    reportId: number().min(1)
});

export const DeleteIndividualReportRequestSchema = object({
    id: number().min(1),
    publicationId: number().min(1)
});

export const GetIndividualReportsStatesForAuthorSchema = object({
    authorId: string().minLength(1)
});

export const UpdateIndividualReportStateForAuthorSchema = object({
    authorId: string().minLength(1),
    reportId: number().min(1),
    state: boolean()
});

export const SearchTagsRequestSchema = object({
    q: string().minLength(2)
});

const SearchArticlesBySearchTermRequestSchema = object({
    q: string().minLength(3),
    publicationId: number().min(1).optional()
});

const SearchArticlesByIdsRequestSchema = object({
    ids: array()
        .of(string().minLength(1).maxLength(100))
        .minLength(1)
        .maxLength(100),
    publicationId: number().min(1).optional()
});

export const SearchArticlesRequestSchema = union(
    SearchArticlesBySearchTermRequestSchema
).or(SearchArticlesByIdsRequestSchema);

const SearchEventsBySearchTermRequestSchema = object({
    q: string().minLength(3),
    publicationId: number().min(1).optional()
});

const SearchEventsByNamesRequestSchema = object({
    ids: array()
        .of(string().minLength(1).maxLength(500))
        .minLength(1)
        .maxLength(100),
    publicationId: number().min(1).optional()
});

export const SearchAttributesRequestSchema = union(
    SearchEventsBySearchTermRequestSchema
).or(SearchEventsByNamesRequestSchema);

export const GetHiddenArticlesRequestSchema = object({
    publicationId: number().min(1)
});

export const HideArticleRequestSchema = object({
    articleId: string().minLength(1),
    publicationId: number().min(1)
});

export const UnhideArticleRequestSchema = object({
    articleId: string().minLength(1),
    publicationId: number().min(1)
});

export const SearchLogsRequestSchema = DateIntervalSchema;

export const SearchImportsRequestSchema = object({
    month: date().acceptJsonString(),
    publicationId: number().min(1).isInteger().optional()
});

export const ImportRequestSchema = object({
    date: date().acceptJsonString(),
    publicationId: number().min(1).isInteger()
});

export const SearchAuthorRequestSchema = object({
    q: union(string().minLength(2)).or(
        array().of(string().minLength(2)).minLength(1)
    )
});

export const AuthorsStatsRequestSchema = object({
    id: string().optional(),
    email: string().optional(),
    fullNameLike: string().optional(),
    publishedInPublicationId: number().optional(),
    page: number().min(1),
    pageSize: number().min(10)
});

export const AuthorSyncWithSitecoreRequestSchema = object({
    id: string().minLength(1)
});

export const UpdateAuthorRequestSchema = object({
    id: string().minLength(1),
    author: object({
        emailingReportsEnabled: boolean().optional(),
        fullName: string().minLength(1).maxLength(300).optional(),
        picture: string().maxLength(400).optional(),
        email: string()
            .optional()
            .addValidator((val) => {
                if (isValidEmail(val)) {
                    return { valid: true };
                }
                return {
                    valid: false,
                    errors: [`${val} is not a valid email`]
                };
            }),
        doNotSyncAutomatically: boolean().optional()
    })
});

export const FindAuthorByIdRequestSchema = object({
    id: string().minLength(1)
});

export const GetPublicationRequestSchema = object({
    id: number().min(1)
});

export const UpdatePublicationRequestSchema = object({
    id: number().min(1),
    dataStartsFrom: date().acceptJsonString().optional(),
    domain: string().minLength(5).maxLength(200).optional(),
    name: string().minLength(1).maxLength(200).optional(),
    googleAnalyticsViewId: string().minLength(1).maxLength(200).optional()
});

export const GetEditorialAnalyticsSettingsRequestSchema = object({
    publicationId: number().min(1)
});

export const SettingUpdateRequestSchema = object({
    publicationId: number().min(1),
    settings: array()
        .of(
            object({
                id: string().minLength(1),
                value: string().minLength(1)
            })
        )
        .minLength(1)
        .addValidator((val) => {
            const ids = {};
            for (let i = 0; i < val.length; i++) {
                const key = `${val[i].id}_${val[i].publicationId}`;
                if (ids[key])
                    return {
                        valid: false,
                        errors: [
                            {
                                message: `id ${val[i].id} is duplicated for publication ${val[i].publicationId}`
                            }
                        ]
                    };
                ids[key] = true;
            }
            return {
                valid: true
            };
        })
});

export const GetCountriesRequestSchema = object({
    withRegions: boolean().optional()
});

export const SearchCountriesRequestSchema = object({
    query: string().minLength(3)
});

export const PageQuerySchema = object({
    page: number().min(1),
    pageSize: number().addValidator((value) =>
        FrontendConfig.DataPageSizes.includes(value)
            ? { valid: true }
            : { valid: false, errors: [{ message: 'invalid page size' }] }
    )
});

export const ExportEditorialReportSchema = object({
    specification: EditorialReportSpecificationSchema,
    type: union(string('first_n')).or(string('all')).or(string('one')),
    format: string('csv'),
    page: number().min(1).optional()
});

export const ExportMarketingReportSchema = object({
    specification: MarketingReportSpecificationSchema,
    type: union(string('first_n')).or(string('all')).or(string('one')),
    format: string('csv'),
    page: number().min(1).optional()
});

export const ColumnFormatSchema = union(string('decimal'))
    .or(string('integer'))
    .or(string('percentage'))
    .or(string('time'))
    .or(string('money'))
    .or(string('history'))
    .or(string('article'))
    .or(string('author'))
    .or(string('utm_campaign'))
    .or(string('utm_source'));

const DividedByColumnModifierDefinitionSchema = object({
    dividedBy: DefaultEditorialReportColumnsSchema
});

const DenominatorOfColumnModifierDefinitionSchema = object({
    denominatorOf: DefaultEditorialReportColumnsSchema
});

const ColumnModifierDefinitionSchema = union(
    DividedByColumnModifierDefinitionSchema
).or(DenominatorOfColumnModifierDefinitionSchema);

export const OperationSchema = union(string('sum'))
    .or(string('avg'))
    .or(string('max'))
    .or(string('min'))
    .or(string('uniq'))
    .or(string('median'));

export const GroupByOperationSchema = OperationSchema.or(
    string('sumForEach')
).or(string('avgWeighted'));

export const AggregateWidgetSchema = object({
    /**
     * Title of the widget
     */
    title: string().minLength(1),
    /**
     * Set of hints to display in the widget
     */
    hint: object({
        sum: string().optional(),
        avg: string().optional(),
        max: string().optional(),
        min: string().optional(),
        uniq: string().optional(),
        median: string().optional()
    }).optional(),
    /**
     * This metric will be displayed in the widget by default,
     */
    defaultMetric: OperationSchema.or(string('default')),
    /**
     * Applicable only when `hasAggreateWidget` is `true`.
     * Defines the order of the widget in the list of widgets. The smaller the value the
     * lefter the widget will be displayed.
     */
    sortOrder: number().optional()
});

const ColumnDefinitionSchemaBase = object({
    /**
     * Short name of the column displayed in the table
     */
    label: string().minLength(1),
    /**
     * API name of the column
     */
    apiName: string().matches(/^[a-z0-9]+$/i),
    /**
     * Hint to display in the column header
     */
    hint: string().optional(),
    /**
     * Applicable only when `format` is `integer`, `decimal`, `percentage` or `money`.
     * If present then aggregated value will be displayed under the main chart.
     */
    aggregateWidget: AggregateWidgetSchema.optional(),
    /**
     * If `true` then only aggregate widget (if present) will be displayed,
     * column will be hidden from the table
     */
    hideFromTable: boolean().optional(),
    /**
     * Applicable only when `format` is `integer`, `decimal`, `percentage` or `money`.
     * You can modify the value for example by dividing it by another column value, etc.
     */
    modifier: ColumnModifierDefinitionSchema.optional(),
    /**
     * Defines report types to show this column for (all if not defined)
     */
    showForReportTypes: array()
        .of(EditorialReportTypeSchema)
        .minLength(1)
        .optional(),
    /**
     * Defines Show By values to show this column for (all if not defined)
     */
    showForShowBy: array()
        .of(union(EditorialReportShowBySchema).or(MarketingReportShowBySchema))
        .minLength(1)
        .optional(),
    /**
     * Format of the column value, value will be formatted according to this value
     */
    format: ColumnFormatSchema,
    /**
     * If `true` then column will be sortable in the result table
     */
    sortable: boolean(),
    /**
     * If `true` then data will be sorted by this column by default
     */
    isDefaultSortColumn: boolean().optional(),
    /**
     * The column will be displayed in the end of the table if `true`
     */
    pushToRight: boolean().optional(),
    /**
     * Defines operation performed on GroupBy if present, otherwise 0 is used
     */
    groupByOperation: GroupByOperationSchema.optional(),
    groupByOperationParamColumns: array()
        .of(string().minLength(1))
        .minLength(1)
        .maxLength(1)
        .optional(),
    /**
     * If `true` clickhouse's -If function aggregator will be added to the column
     * and the column value will be calculated only for the rows where the condition is met
     */
    filters: ReportSpecificationFiltersSchema.optional()
});

const DefaultColumnDefinitionSchema = ColumnDefinitionSchemaBase.addProps({
    type: union(string('default')).or(string('default_history')),
    defaultColumnName: string().minLength(1)
});

const EventColumnDefinitionSchema = ColumnDefinitionSchemaBase.addProps({
    type: union(string('event')).or(string('event_history')),
    eventName: union(string().minLength(1)).or(StringFilterConditionSchema)
});

const EventCountColumnDefinitionSchema = union(
    EventColumnDefinitionSchema.addProps({
        calculation: string('count')
    })
).or(
    EventColumnDefinitionSchema.addProps({
        calculation: string('uniq'),
        uniqColumnName: string().minLength(1)
    })
);

export const ColumnDefinitionSchema = union(DefaultColumnDefinitionSchema).or(
    EventCountColumnDefinitionSchema
);

export const ColumnGroupSchema = object({
    id: number().min(1).optional(),
    name: string().minLength(1),
    columns: array().of(ColumnDefinitionSchema).minLength(1)
});

export const GetColumnGroupsForPublicationRequestSchema = object({
    type: AnalyticsReportTypeSchema,
    publicationId: number().min(1)
});

export const GetColumnGroupsByIdsRequestSchema = object({
    reportType: AnalyticsReportTypeSchema,
    publicationId: number().min(1),
    ids: array().of(number().min(1)).minLength(1)
});

export const DeleteColumnGroupRequestSchema = object({
    id: number().min(1)
});

export const CreateColumnGroupRequestSchema = object({
    reportType: AnalyticsReportTypeSchema,
    publicationId: number().min(1),
    group: ColumnGroupSchema.omit('id')
});

export const CreateColumnGroupResponseSchema = object({
    id: number().min(1),
    /**
     * All groups for publication
     */
    groups: array().of(ColumnGroupSchema.makePropRequired('id'))
});

export const UpdateColumnGroupRequestSchema = object({
    /**
     * Column group id
     */
    id: number().min(1),
    group: ColumnGroupSchema.omit('id')
});

export const UpdateColumnGroupResponseSchema = object({
    id: number().min(1),
    /**
     * All groups for publication
     */
    groups: array().of(ColumnGroupSchema.makePropRequired('id'))
});
