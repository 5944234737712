export const AGGREGATE_WIDGET_VARIATIONS = [
    {
        key: 'default',
        title: 'Total',
        default: 'Hint for Total Value'
    },
    {
        key: 'sum',
        title: 'Sum',
        default: 'Hint for Sum Aggregate'
    },
    {
        key: 'avg',
        title: 'Average',
        default: 'Hint for Average Aggregate'
    },
    {
        key: 'min',
        title: 'Minimum',
        default: 'Hint for Miniumum Aggregate'
    },
    {
        key: 'max',
        title: 'Maximum',
        default: 'Hint for Maximum Aggregate'
    },
    {
        key: 'uniq',
        title: 'Unique',
        default: 'Hint for Unique Aggregate'
    },
    {
        key: 'median',
        title: 'Median',
        default: 'Hint for Median Aggregate'
    }
];
