import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';

const loadingStyles = {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
};

export const LongLoading = ({ children }) => (
    <Suspense
        fallback={
            <div style={loadingStyles}>
                <CircularProgress />
            </div>
        }
    >
        {children}
    </Suspense>
);
