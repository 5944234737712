const getFormatMonth = (locale, timeZone) =>
    Intl.DateTimeFormat(locale, { month: 'short', timeZone }).format;
const getFormatDate = (locale, timeZone) =>
    Intl.DateTimeFormat(locale, { day: '2-digit', timeZone }).format;
const getFormatYear = (locale, timeZone) =>
    Intl.DateTimeFormat(locale, { year: 'numeric', timeZone }).format;
const getFormatHour = (locale, timeZone) =>
    Intl.DateTimeFormat(locale, { hourCycle: 'h24', hour: '2-digit', timeZone })
        .format;
const getFormatMinute = (locale, timeZone) =>
    Intl.DateTimeFormat(locale, { minute: '2-digit', timeZone }).format;
const getFormatSecond = (locale, timeZone) =>
    Intl.DateTimeFormat(locale, { second: '2-digit', timeZone }).format;

const getDateFormatters = (locale, timeZone) => ({
    month: getFormatMonth(locale, timeZone),
    date: getFormatDate(locale, timeZone),
    year: getFormatYear(locale, timeZone),
    hour: getFormatHour(locale, timeZone),
    minute: getFormatMinute(locale, timeZone),
    second: getFormatSecond(locale, timeZone)
});

/**
 *
 * @param {string?} locale - defaults to 'en-us'
 * @param {boolean?} withTime - defaults to false
 * @param {string?} datePartSeparator - defaults to '-'
 * @param {string?} timePartSeparator - defaults to ':'
 * @param {string?} timeZone - defaults to 'UTC'
 * @returns {(Date) => string}
 */
export const getFormatDateUniversal = (
    locale = 'en-us',
    withTime = false,
    datePartSeparator = '-',
    timePartSeparator = ':',
    timeZone = 'UTC'
) => {
    const { month, date, year, hour, minute, second } = getDateFormatters(
        locale,
        timeZone
    );

    return (d) =>
        `${date(d)}${datePartSeparator}${month(d)}${datePartSeparator}${year(
            d
        )}${
            withTime
                ? ` ${hour(d)}${timePartSeparator}${minute(
                      d
                  )}${timePartSeparator}${second(d)}`
                : ''
        }${withTime && timeZone ? ` ${timeZone}` : ''}`;
};
