import { padLeft } from './pad.js';

const secondLenght = 1000;
const minuteLength = 60 * secondLenght;
const hourLength = 60 * minuteLength;
const dayLength = 24 * hourLength;

/**
 *
 * @param {Date} d
 * @param {number} n
 * @returns {Date}
 */
export const addDays = (d, n) =>
    n ? new Date(d.getTime() + dayLength * n) : d;

/**
 *
 * @param {Date} d
 * @param {number} n
 * @returns {Date}
 */
export const addMonths = (d, n) =>
    n
        ? new Date(
              Date.UTC(
                  d.getUTCFullYear(),
                  d.getUTCMonth() + n,
                  d.getUTCDate(),
                  d.getUTCHours(),
                  d.getUTCMinutes(),
                  d.getUTCSeconds(),
                  d.getUTCMilliseconds()
              )
          )
        : d;

/**
 *
 * @param {Date} d
 * @param {number} n
 * @returns {Date}
 */
export const addQuarters = (d, n) =>
    n
        ? new Date(
              Date.UTC(
                  d.getUTCFullYear(),
                  d.getUTCMonth() + n * 3,
                  d.getUTCDate(),
                  d.getUTCHours(),
                  d.getUTCMinutes(),
                  d.getUTCSeconds(),
                  d.getUTCMilliseconds()
              )
          )
        : d;

/**
 *
 * @param {Date} d
 * @param {number} n
 * @returns {Date}
 */
export const addYears = (d, n) =>
    n
        ? new Date(
              Date.UTC(
                  d.getUTCFullYear(),
                  d.getUTCMonth() + n * 12,
                  d.getUTCDate(),
                  d.getUTCHours(),
                  d.getUTCMinutes(),
                  d.getUTCSeconds(),
                  d.getUTCMilliseconds()
              )
          )
        : d;

/**
 *
 * @param {Date} d
 * @returns {Date}
 */
export const getDayStart = (d) =>
    new Date(
        Date.UTC(
            d.getUTCFullYear(),
            d.getUTCMonth(),
            d.getUTCDate(),
            0,
            0,
            0,
            0
        )
    );
/**
 *
 * @param {Date} d1
 * @param {Date} d2
 * @returns {number}
 */
export const getDatesDiffInDays = (d1, d2) =>
    Math.round(Math.floor((getDayStart(d1) - getDayStart(d2)) / dayLength));

/**
 *
 * @param {Date} d
 * @returns {Date}
 */
export const getMonthFirstDay = (d) =>
    new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), 1, 0, 0, 0, 0));

/**
 *
 * @param {Date} d
 * @returns {Date}
 */
export const getWeekFirstDay = (d) => getDayStart(addDays(d, -d.getUTCDay()));

/**
 *
 * @param {Date} d
 * @returns {Date}
 */
export const getQuarterFirstDay = (d) =>
    new Date(
        Date.UTC(
            d.getUTCFullYear(),
            d.getUTCMonth() - (d.getUTCMonth() % 3),
            1,
            0,
            0,
            0,
            0
        )
    );

/**
 *
 * @param {Date} d
 * @returns {Date}
 */
export const getQuarterLastDay = (d) =>
    addDays(
        new Date(
            Date.UTC(
                d.getUTCFullYear(),
                d.getUTCMonth() + 3 - (d.getUTCMonth() % 3),
                1,
                0,
                0,
                0,
                0
            )
        ),
        -1
    );

/**
 *
 * @param {Date} d
 * @returns {Date}
 */
export const getMonthLastDay = (d) =>
    addDays(
        new Date(
            Date.UTC(d.getUTCFullYear(), d.getUTCMonth() + 1, 1, 0, 0, 0, 0)
        ),
        -1
    );

/**
 *
 * @returns {Date}
 */
export const getPrevMonthFirstDay = () => {
    const now = new Date();
    return new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth() - 1, 1, 0, 0, 0)
    );
};

/**
 * Formats `date` as `YYYY-MM-DD` format
 * @param {Date} date
 * @returns {string}
 */
export const getDateStr = (date, delimiter = '-') =>
    [
        padLeft(date.getUTCFullYear().toString(), 4),
        padLeft((date.getUTCMonth() + 1).toString(), 2),
        padLeft(date.getUTCDate().toString(), 2)
    ].join(delimiter);

/**
 * Takes `dateStr` as `YYYY-MM-DD` string and returns parsed Date in UTC
 * @param {string} dateStr
 */
export const dateStrToUtcDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-').map((v) => {
        if (!v) throw new Error('wrong format');
        const res = parseInt(v, 10);
        if (res <= 0) {
            throw new Error('wrong format');
        }
        return res;
    });
    if (
        typeof year !== 'number' ||
        typeof month !== 'number' ||
        typeof day !== 'number'
    )
        throw new Error('wrong format');

    return new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));
};

export const getDayNameFn = (format = 'long') =>
    Intl.DateTimeFormat(
        typeof navigator !== 'undefined' ? navigator.language : 'en-us',
        {
            weekday: format,
            timeZone: 'UTC'
        }
    ).format;

/**
 *
 * @param {Date} date
 * @returns {string}
 */
export const getDateWithLongMonth = (date) =>
    Intl.DateTimeFormat(
        typeof navigator !== 'undefined' ? navigator.language : 'en-us',
        {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC'
        }
    ).format(date);

export const getDayNameLong = (date) => getDayNameFn('long')(date);

export const getDayNameNarrow = (date) => getDayNameFn('narrow')(date);

export const getMonthWithYearNameFn = (format = 'long') =>
    Intl.DateTimeFormat(
        typeof navigator !== 'undefined' ? navigator.language : 'en-us',
        {
            year: 'numeric',
            month: format,
            timeZone: 'UTC'
        }
    ).format;

export const getMonthWithYearNameShort = (date) =>
    getMonthWithYearNameFn('short')(date);

export const isValidDate = (str) => !Number.isNaN(Date.parse(str));

export const ensureIsDate = (strOrDate) => {
    if (typeof strOrDate === 'string') {
        const time = Date.parse(strOrDate);
        if (Number.isNaN(time)) {
            throw new Error('cannot parse string');
        }

        return new Date(time);
    }
    return strOrDate;
};

/**
 *
 * @typedef {object} DateComponents
 * @property {number} year
 * @property {number} month
 * @property {number} day
 */

/**
 *
 * @param {Date} date
 * @returns {DateComponents}
 */
export const getDateComponents = (date) => ({
    year: date.getUTCFullYear(),
    month: date.getUTCMonth() + 1,
    day: date.getUTCDate()
});

/**
 *
 * @param {DateComponents} components
 * @returns {Date}
 */
export const getDateFromComponents = (components) =>
    new Date(components.year, components.month - 1, components.day, 0, 0, 0, 0);
