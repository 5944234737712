import { getRequestHeaders } from './getRequestHeaders';

/**
 * @param {import('axios').AxiosRequestConfig<any>} requestConfig
 * @returns {import('axios').AxiosRequestConfig<any>}
 */
const decorateRequestConfig = (requestConfig) => ({
    ...requestConfig,
    headers: getRequestHeaders(requestConfig),
    method: requestConfig.method,
    ...(requestConfig.data && { body: JSON.stringify(requestConfig.data) })
});

export { decorateRequestConfig };
