import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {string | string[]} term
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').AuthorDTO[]>>}
 */
const findAuthors = (term) => {
    return asyncFetch(
        'analytics/search-authors',
        requestConfig({
            q: term
        })
    );
};

export { findAuthors };
