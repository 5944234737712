import { useQueryParams } from 'src/hooks/useQueryParams';

import { Grid, Button } from '@mui/material';
import { Login } from '@mui/icons-material';

import { FrontendConfig } from 'smg-iq-configs';

const LoginComponent = () => {
    const params = useQueryParams();

    return (
        <Grid
            container
            spacing={3}
            style={{
                height: '100%'
            }}
        >
            <Grid
                item
                md={12}
                xs={12}
                style={{
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Button
                    href={`${FrontendConfig.BackendBaseUrl}/auth/login${
                        params.get('to')
                            ? `?to=${encodeURIComponent(params.get('to'))}`
                            : ''
                    }`}
                    startIcon={<Login />}
                    variant="outlined"
                >
                    Login
                </Button>
            </Grid>
        </Grid>
    );
};

LoginComponent.propTypes = {};

export { LoginComponent };
