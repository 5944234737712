import PropTypes from 'prop-types';
import { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

import {
    NotificationsContext,
    context
} from '../../../contexts/NotificationsContext';

const NotificationProvider = ({ children }) => {
    const [contextState] = useState(context);
    const [open, setOpen] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('info');

    contextState.on('info', (str) => {
        setNotificationMsg(str);
        setAlertSeverity('info');
        setOpen(true);
    });

    contextState.on('success', (str) => {
        setNotificationMsg(str);
        setAlertSeverity('success');
        setOpen(true);
    });

    contextState.on('error', (err) => {
        if (err instanceof Error) {
            setNotificationMsg(err.message);
        } else if (typeof err === 'string') {
            setNotificationMsg(err);
        } else {
            setNotificationMsg('Error occured');
        }

        setAlertSeverity('error');
        setOpen(true);
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <NotificationsContext.Provider value={contextState}>
            <Snackbar open={open} onClose={handleClose} autoHideDuration={5000}>
                <Alert severity={alertSeverity} onClose={handleClose}>
                    {notificationMsg}
                </Alert>
            </Snackbar>
            {children}
        </NotificationsContext.Provider>
    );
};

NotificationProvider.propTypes = {
    children: PropTypes.any
};

export { NotificationProvider };
