import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').SaveReportRequest} req
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').SavedReportDTO>>}
 */
export const saveReport = async (req) =>
    asyncFetch('analytics/save-report', requestConfig(req));
