import {
    formatISODate,
    getMonthFirstDay,
    getMonthLastDay,
    addMonths
} from 'smg-iq-utils';

export const DATE_RANGE_PATTERN =
    /^((\d{4})-(\d{2})-(\d{2}))\+((\d{4})-(\d{2})-(\d{2}))$/;

export const DEFAULT_RANGE_VALUE = `${formatISODate(
    addMonths(getMonthFirstDay(new Date()), -18)
)}+${formatISODate(getMonthLastDay(addMonths(new Date(), 1)))}`;
