import { getMonthsWithArticles } from './getMonthsWithArticles.js';
import { saveSettings } from './saveSettings.js';
import { getSettings } from './getSettings.js';
import { getAuthorsStats } from './getAuthorsStats.js';
import { getAuthorStats } from './getAuthorStats.js';
import { getAuthorById } from './getAuthorById.js';
import { findAuthors } from './findAuthors.js';
import { getMonthData } from './getMonthData.js';
import { saveMonthData } from './saveMonthData.js';
import { editorialReport } from './editorialReport.js';
import { marketingReport } from './marketingReport.js';
import { exportEditorialReport } from './exportEditorialReport.js';
import { exportMarketingReport } from './exportMarketingReport.js';
import { searchTags } from './searchTags.js';
import { searchArticles } from './searchArticles.js';
import { searchEvents } from './searchEvents.js';
import { searchUrls } from './searchUrls.js';
import { searchUtmCampaign } from './searchUtmCampaigns.js';
import { searchUtmSource } from './searchUtmSources.js';
import { searchUtmMedium } from './searchUtmMediums.js';
import { searchUtmTerm } from './searchUtmTerm.js';
import { searchUtmContent } from './searchUtmContent.js';
import { getHiddenArticles } from './getHiddenArticles.js';
import { unhideArticle } from './unhideArticle.js';
import { hideArticle } from './hideArticle.js';
import { getAuthors } from './getAuthors.js';
import { updateAuthor } from './updateAuthor.js';
import { syncAuthorWithSitecore } from './syncAuthorWithSitecore.js';
import { getAuthorTypes } from './getAuthorTypes.js';
import { getArticleTypes } from './getArticleTypes.js';
import { getPublication } from './getPublication.js';
import { getPublications } from './getPublications.js';
import { savePublication } from './savePublication.js';
import { getArticleCategories } from './getArticleCategories.js';
import { searchLogs } from './searchLogs.js';
import { searchImports } from './searchImports.js';
import { requestImport } from './requestImport.js';
import { saveReport } from './saveReport.js';
import { updateReport } from './updateReport.js';
import { getSavedEditorialReports } from './getSavedEditorialReports.js';
import { getSavedMarketingReports } from './getSavedMarketingReports.js';
import { deleteSavedReport } from './deleteSavedReport.js';
import { cloneSavedReport } from './cloneSavedReport.js';
import { getIndividualReportsForPublication } from './getIndividualReportsForPublication.js';
import { saveIndividualReportsForPublication } from './saveIndividualReportsForPublication.js';
import { updateIndividualReportsForPublication } from './updateIndividualReportsForPublication.js';
import { deleteIndividualReport } from './deleteIndividualReport.js';
import { getIndividualReportsStatesForAuthor } from './getIndividualReportsStateForAuthor.js';
import { getIndividualReportRecipients } from './getIndividualReportRecipients.js';
import { updateIndividualReportStateForAuthor } from './updateIndividualReportStateForAuthor.js';
import { getColumnGroupsForPublication } from './getColumnGroupsForPublication.js';
import { getColumnGroupsByIds } from './getColumnGroupsByIds.js';
import { deleteColumnGroup } from './deleteColumnGroup.js';
import { createColumnGroup } from './createColumnGroup.js';
import { updateColumnGroup } from './updateColumnGroup.js';

export const EditorialAnalyticsApi = {
    getMonthsWithArticles,
    saveSettings,
    getSettings,
    getArticleCategories,
    getAuthors,
    updateAuthor,
    syncAuthorWithSitecore,
    getAuthorById,
    getAuthorStats,
    getAuthorsStats,
    findAuthors,
    getMonthData,
    saveMonthData,
    editorialReport,
    marketingReport,
    exportEditorialReport,
    exportMarketingReport,
    searchTags,
    searchArticles,
    searchEvents,
    searchUrls,
    searchUtmCampaign,
    searchUtmSource,
    searchUtmMedium,
    searchUtmTerm,
    searchUtmContent,
    getHiddenArticles,
    hideArticle,
    unhideArticle,
    getAuthorTypes,
    getArticleTypes,
    getPublication,
    getPublications,
    savePublication,
    searchLogs,
    searchImports,
    requestImport,
    saveReport,
    updateReport,
    getSavedEditorialReports,
    getSavedMarketingReports,
    deleteSavedReport,
    cloneSavedReport,
    getIndividualReportsForPublication,
    saveIndividualReportsForPublication,
    updateIndividualReportsForPublication,
    deleteIndividualReport,
    getIndividualReportsStatesForAuthor,
    getIndividualReportRecipients,
    updateIndividualReportStateForAuthor,
    getColumnGroupsForPublication,
    getColumnGroupsByIds,
    deleteColumnGroup,
    createColumnGroup,
    updateColumnGroup
};
