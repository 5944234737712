export default {
   "BackendBaseUrl": "https://iq-qa.simplermedia.com/api/v1",
   "FrontendBaseUrl": "https://iq-qa.simplermedia.com",
   "DataPageSizes": [
      10,
      20,
      30,
      50
   ],
   "EditorialReportsDefaultPublicationIdLocalStorageKey": "editorial-analytics-reports-default-publication-id"
};