const DEFAULT_TIMEOUT = 180 * 1000;

export default class CacheMap extends Map {
    #expirationTimeout = DEFAULT_TIMEOUT;

    #timers = new Map();

    #tryUnrefTimer(timer) {
        if (timer && typeof timer.unref === 'function') {
            timer.unref();
        }
    }

    constructor(expirationTimeout = DEFAULT_TIMEOUT, ...rest) {
        super(...rest);
        this.#expirationTimeout = expirationTimeout;
        if (Array.isArray(rest)) {
            for (let i = 0; i < rest.length; i++) {
                if (Array.isArray(rest[i])) {
                    const keysToInvalidate = rest[i][0];
                    const timer = setTimeout(() => {
                        this.delete(keysToInvalidate);
                    }, this.#expirationTimeout);
                    this.#tryUnrefTimer(timer);
                    this.#timers.set(keysToInvalidate, timer);
                }
            }
        }
    }

    dispose() {
        for (const [key] of this.#timers) {
            clearTimeout(key);
            this.#timers.delete(key);
        }
    }

    clear() {
        for (const [key, value] of this.#timers) {
            clearTimeout(value);
            this.#timers.delete(key);
        }
        super.clear();
    }

    delete(key) {
        if (this.has(key)) {
            super.delete(key);
            if (this.#timers.has(key)) {
                const timer = this.#timers.get(key);
                clearTimeout(timer);
                this.#timers.delete(key);
            }
        }
    }

    set(key, value, timeout = this.#expirationTimeout) {
        super.set(key, value);
        const timer = setTimeout(() => {
            if (this.has(key)) {
                this.delete(key);
            }
        }, timeout);
        this.#tryUnrefTimer(timer);
        this.#timers.set(key, timer);
    }
}
