import { CacheMap } from 'smg-iq-utils';
import { asyncFetch } from 'src/api/utils/asyncFetch';

const cache = new CacheMap(1000 * 60 * 5);

const requestConfig = (data) => ({
    method: 'GET',
    data
});

/**
 *
 * @returns {Promise<import('smg-iq').ApiCallResult<string[]>}
 */
const getArticleCategories = async () => {
    if (cache.has('categories')) {
        return cache.get('categories');
    }
    const categories = await asyncFetch(
        'analytics/article-categories',
        requestConfig({})
    );
    cache.set('categories', categories);
    return categories;
};
export { getArticleCategories };
