/**
 *
 * @param {import('smg-iq').AuthorsStatsQuery} AuthorsStatsQuery
 * @returns {Promise<ApiCallResult<import('smg-iq').AuthorStatsDTO>>}
 */
const getAuthorStats = () => {
    /* keep */
};

export { getAuthorStats };
