import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {Object} props
 * @param {String} props.email
 * @param {Array<String>} roles
 * @returns {Promise<import('smg-iq').ApiCallResult<any>>}
 */
const addUser = ({ email, roles }) =>
    asyncFetch(
        'users/add',
        requestConfig({
            email,
            roles
        })
    );

export { addUser };
