import { logout } from './logout';
import { validateToken } from './validateToken';

// this is being imported directly to have access to the error state
// from the AuthProvider
// logout is also imported directly from AuthContext.js
export { logout } from './logout';
export { validateToken } from './validateToken';

export const AuthApi = {
    logout,
    validateToken
};
