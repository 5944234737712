import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 * @prop {number} id
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').PublicationDTO[]>}
 */
const getPublication = async (id) => {
    return await asyncFetch(
        'analytics/publication-by-id',
        requestConfig({ id })
    );
};

export { getPublication };
