import axios from 'axios';

import {
    ACTION_TYPES,
    INITIAL_STATE,
    DEFAULT_ERROR_MESSAGE
} from '../constants';

import { decorateResponse } from './decorateResponse';

/**
 *
 * @param {string} url
 * @param {import('axios').AxiosRequestConfig<any>} options
 * @returns {import('smg-iq').ApiCallResult}
 */
const fetchData = async (url, options) => {
    let state = INITIAL_STATE;

    try {
        const response = await axios(url, options);
        if (options.raw) {
            return decorateResponse({
                type: ACTION_TYPES.success,
                payload: response.data
            });
        }
        state = response.data?.success
            ? decorateResponse({
                  type: ACTION_TYPES.success,
                  payload: response.data.data
              })
            : decorateResponse({
                  type: ACTION_TYPES.application_error,
                  payload: response.data?.error || DEFAULT_ERROR_MESSAGE
              });
    } catch (error) {
        if (error.response.status === 401) {
            state = decorateResponse({
                type: ACTION_TYPES.unathorized
            });
        } else if (error.response.status === 403) {
            state = decorateResponse({
                type: ACTION_TYPES.forbidden
            });
        } else {
            state = decorateResponse({
                type: ACTION_TYPES.failure,
                payload: error.response && error.response.data
            });
        }
    }

    return state;
};

export { fetchData };
