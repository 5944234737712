import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').ImportRequest} spec
 * @returns {Promise<import('smg-iq').ApiCallResult<number>>}
 */
const requestImport = async (spec) =>
    asyncFetch('analytics/request-import', requestConfig(spec));

export { requestImport };
