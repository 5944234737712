import { CacheMap } from 'smg-iq-utils';

import { asyncFetch } from 'src/api/utils/asyncFetch';

const cache = new CacheMap(1000 * 60 * 5);

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').GetCountriesRequest} request
 * @returns {string}
 */
const getRequestKey = (request) =>
    `countries${request?.withRegions ? '_with_regions' : ''}`;

/**
 *
 * @param {import('smg-iq').GetCountriesRequest} request
 * @returns {Promise<import('smg-iq').ApiCallResult<string[] | { country: string; regions: string[]; }[]>>}
 */
const getAll = async (request) => {
    const key = getRequestKey(request);

    if (cache.has(key)) {
        return cache.get(key);
    }
    const countries = asyncFetch(
        'countries/get-all',
        requestConfig({ ...request })
    );
    cache.set(key, countries);

    return countries;
};
export { getAll };
