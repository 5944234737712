import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').EditorialReportSpecification} spec
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').ReportRequestResult>>}
 */
export const editorialReport = async (spec) =>
    asyncFetch('analytics/report/editorial', requestConfig(spec));
