/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const CampaignSessionCampaignColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.campaign',
        apiName: 'campaign',
        format: 'utm_campaign',
        label: 'Campaign',
        hint: 'Campaign',
        sortable: true,
        isDefaultSortColumn: true
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const CampaignUsersColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.users',
        apiName: 'users',
        format: 'integer',
        label: 'Users',
        hint: 'Count of uniq users who visited the page in the selected period',
        sortable: true,
        isDefaultSortColumn: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const CampaignByVisitorsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.users',
        apiName: 'visitorsHistory',
        format: 'history',
        label: 'Users Trend',
        hint: 'History of the number of visitors of the page in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const CampaignPageViewsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.pageViews',
        apiName: 'pageViews',
        format: 'integer',
        label: 'Page Views',
        hint: 'Number of page views in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const CampaignSessionsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.sessions',
        apiName: 'sessions',
        format: 'integer',
        label: 'Sessions',
        hint: 'Number of sessions in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const CampaignAvgSessionDurationColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.avgSessionDuration',
        apiName: 'avgSessionDuration',
        format: 'time',
        label: 'Avg. Session Duration',
        hint: 'Average duration of a session in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};
