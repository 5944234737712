import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {import('smg-iq').EditorialAnalyticsSettingUpdateRequest} request
 * @returns {Promise<import('smg-iq').ApiCallResult<Array<import('smg-iq').EditorialAnalyticsSetting>>>}
 */
const saveSettings = (request) =>
    asyncFetch('analytics/save-settings', requestConfig(request));

export { saveSettings };
