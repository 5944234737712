import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'PUT',
    data
});

/**
 *
 * @param {string} id
 * @param {Omit<Partial<import('smg-iq').AuthorDTO>,'id'>} author
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').AuthorDTO>>}
 */
const updateAuthor = (id, author) =>
    asyncFetch(
        'author',
        requestConfig({
            id,
            author
        })
    );

export { updateAuthor };
