import { asyncFetch } from 'src/api/utils/asyncFetch';

const requestConfig = (data) => ({
    method: 'POST',
    data
});

/**
 *
 * @param {string} email
 * @returns {Promise<import('smg-iq').ApiCallResult<any>>}
 */
const deleteUser = (email) =>
    asyncFetch('users/delete', requestConfig({ email }));

export { deleteUser };
