import { useStateWithCallback } from '../useStateWithCallback';

/** That's how we can manipulate a global state in GlobalProvider's children:
 * @example
 * import useGlobalContext from 'src/hooks/useGlobalcontext';
 * const { data, setData } = useGlobalContext();
 */
const useGlobalProvider = () => {
    const [refetchCount, setRefetchCount] = useStateWithCallback(0);
    const [filteredAuthor, setFilteredAuthor] = useStateWithCallback('');

    return {
        refetchCount,
        setRefetchCount,
        filteredAuthor,
        setFilteredAuthor
    };
};

export { useGlobalProvider };
