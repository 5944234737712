import { padLeft } from './pad.js';

const formatSeconds = (seconds) => {
    const format = (val, len = 2) => padLeft(val.toFixed(0).toString(), len);
    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = secondsInHour * 24;

    const days = Math.floor(seconds / secondsInDay);
    const hours = Math.floor((seconds - days * secondsInDay) / secondsInHour);
    const minutes = Math.floor(
        (seconds - days * secondsInDay - hours * secondsInHour) /
            secondsInMinute
    );

    const abbreviations = ['d', 'h', '', ''];
    const separators = [' ', ' ', ':', ''];
    const lengths = [0, 2, 2, 2];

    return [days, hours, minutes, seconds % 60]
        .map((value, index) =>
            Math.floor(value) > 0 || index >= 2
                ? `${format(value, lengths[index])}${abbreviations[index]}${
                      separators[index]
                  }`
                : 0
        )
        .filter((v) => v)
        .join('');
};

export { formatSeconds };
