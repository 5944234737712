import { useCallback } from 'react';
import { useNotificationsContext } from 'src/hooks/useContext';

const useNotification = () => {
    const { showError, showInfo, showSuccess } = useNotificationsContext();
    return useCallback(
        /**
         *
         * @param {'info' | 'error' | 'success'} type
         * @param {*} msg
         */
        (type, msg) => {
            if (type === 'error') {
                showError(msg);
            } else if (type === 'info') {
                showInfo(msg);
            } else if (type === 'success') {
                showSuccess(msg);
            }
        },
        [showError, showInfo, showSuccess]
    );
};

export { useNotification };
