import {
    NavLink as RouterLink,
    matchPath,
    useLocation
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { UserRolesConfig } from 'smg-iq-configs';

import { Button, List, ListItem, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ShowForRole } from 'src/components/shared/ShowForRole';

const ShowForRoleIfRoleDefined = ({ role, children }) =>
    role ? <ShowForRole role={role}>{children}</ShowForRole> : children;

const NavItem = ({ href, icon: Icon, title, subItems, ...rest }) => {
    const location = useLocation();

    const isActive = (item) => {
        let res = false;
        if (Array.isArray(item.subItems)) {
            res = item.subItems.filter((s) => isActive(s)).length > 0;
        } else if (item.href) {
            const urlsToMatch = [
                item.href,
                ...(Array.isArray(item.matchedPaths) ? item.matchedPaths : [])
            ];
            res = !!urlsToMatch.find((path) =>
                matchPath(
                    {
                        path,
                        end: true
                    },
                    location.pathname
                )
            );
        }
        return res;
    };

    return (
        <>
            <ListItem
                key="mainItem"
                disableGutters
                sx={{
                    display: 'flex',
                    py: 0
                }}
                {...rest}
            >
                <Button
                    component={RouterLink}
                    sx={{
                        color: 'text.secondary',
                        fontWeight: 'medium',
                        justifyContent: 'flex-start',
                        letterSpacing: 0,
                        py: 1.25,
                        textTransform: 'none',
                        width: '100%',
                        ...(isActive({
                            item: href,
                            subItems
                        }) && {
                            color: 'primary.main'
                        }),
                        '& svg': {
                            mr: 1
                        }
                    }}
                    to={href}
                >
                    {Icon && <Icon size="20" />}
                    <span>{title}</span>
                </Button>
            </ListItem>
            {subItems &&
                subItems.map(
                    ({
                        icon: SubItemIcon,
                        href: SubItemHref,
                        title: SubItemTitle,
                        index: SubItemIndex,
                        matchedPaths: MatchedPaths,
                        role: SubItemRole,
                        children
                    }) => (
                        <ListItem
                            key={`subItem${SubItemIndex}`}
                            disableGutters
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                py: 0
                            }}
                        >
                            <ShowForRoleIfRoleDefined role={SubItemRole}>
                                <Button
                                    component={RouterLink}
                                    sx={{
                                        color: 'text.secondary',
                                        fontWeight: 'medium',
                                        justifyContent: 'flex-start',
                                        letterSpacing: 0,
                                        fontSize: 12,
                                        py: 1,
                                        px: 3,
                                        textTransform: 'none',
                                        width: '100%',
                                        ...(isActive({
                                            href: SubItemHref,
                                            matchedPaths: MatchedPaths
                                        }) && {
                                            color: 'primary.main'
                                        }),
                                        '& svg': {
                                            mr: 1
                                        }
                                    }}
                                    to={SubItemHref}
                                >
                                    {SubItemIcon && (
                                        <SubItemIcon width={18} height={18} />
                                    )}
                                    <span>{SubItemTitle}</span>
                                </Button>
                                {Boolean(children?.length) && (
                                    <List
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        {children?.map(
                                            ({
                                                title,
                                                href,
                                                id,
                                                isActive,
                                                onRemove
                                            }) => {
                                                return (
                                                    <ListItem
                                                        key={id}
                                                        sx={{
                                                            pl: 6,
                                                            pr: 2,
                                                            py: 0.5,
                                                            borderRadius: '4px',
                                                            display: 'flex',
                                                            background: isActive
                                                                ? '#eaeaea'
                                                                : 'transparent',
                                                            justifyContent:
                                                                'space-between',
                                                            '&:hover button': {
                                                                visibility:
                                                                    'visible'
                                                            }
                                                        }}
                                                    >
                                                        <Button
                                                            variant="text"
                                                            href={href}
                                                            key={id}
                                                            sx={{
                                                                minWidth: 0,
                                                                color: '#616161',
                                                                fontSize:
                                                                    '12px',
                                                                textTransform:
                                                                    'none'
                                                            }}
                                                        >
                                                            {title}
                                                        </Button>
                                                        <ShowForRole
                                                            role={
                                                                UserRolesConfig
                                                                    .roles
                                                                    .EDITORIAL_MANAGER
                                                            }
                                                        >
                                                            <IconButton
                                                                edge="end"
                                                                sx={{
                                                                    visibility:
                                                                        'hidden'
                                                                }}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    onRemove();
                                                                }}
                                                            >
                                                                <DeleteIcon
                                                                    sx={{
                                                                        width: '16px',
                                                                        height: '16px'
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </ShowForRole>
                                                    </ListItem>
                                                );
                                            }
                                        )}
                                    </List>
                                )}
                            </ShowForRoleIfRoleDefined>
                        </ListItem>
                    )
                )}
        </>
    );
};

NavItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.elementType,
    title: PropTypes.string,
    subItems: PropTypes.array
};

export { NavItem };
