import { CacheMap } from 'smg-iq-utils';
import { asyncFetch } from 'src/api/utils/asyncFetch';

const cache = new CacheMap(1000 * 60);

const requestConfig = (data) => ({
    method: 'GET',
    data
});

/**
 * @param {boolean} returnAll
 * @returns {Promise<import('smg-iq').ApiCallResult<import('smg-iq').SavedReportDTO[]>>}
 */
export const getSavedMarketingReports = async (returnAll) => {
    if (cache.has('savedReports')) {
        return cache.get('savedReports');
    }
    const reports = await asyncFetch(
        `analytics/marketing/${
            returnAll ? 'all-saved-reports' : 'saved-reports'
        }`,
        requestConfig({})
    );
    cache.set('savedReports', reports);
    return reports;
};
