import PropTypes from 'prop-types';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import InputIcon from '@mui/icons-material/Input';
import { useAuthContext } from 'src/hooks/useContext';

const DashboardNavbar = ({ onNavOpen, ...rest }) => {
    const { commands } = useAuthContext();
    return (
        <AppBar elevation={0} position="fixed" {...rest}>
            <Toolbar>
                <IconButton color="inherit" onClick={onNavOpen} size="large">
                    <MenuIcon />
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <Hidden smDown>
                    <IconButton
                        onClick={() => commands.logout()}
                        color="inherit"
                        size="large"
                    >
                        <InputIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

DashboardNavbar.propTypes = {
    onNavOpen: PropTypes.func
};

export { DashboardNavbar };
