/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.article',
        apiName: 'article',
        format: 'article',
        label: 'Article',
        sortable: false
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleUsersColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.users',
        apiName: 'users',
        format: 'integer',
        label: 'Users',
        hint: 'Count of uniq users who visited the article in the selected period',
        sortable: true,
        isDefaultSortColumn: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleSessionsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.sessions',
        apiName: 'sessions',
        format: 'integer',
        label: 'Sessions',
        hint: 'Number of sessions where the article was viewed in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleSessionsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.sessionsPerArticle',
        apiName: 'sessionsPerArticle',
        format: 'decimal',
        label: 'Sessions / Article',
        hint: 'Average number of sessions per article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticlePageViewsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.pageViews',
        apiName: 'pageViews',
        format: 'integer',
        label: 'Page Views',
        hint: 'Number of page views of the article in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticlePageViewsPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.pageViewsPerArticle',
        apiName: 'pageViewsPerArticle',
        format: 'decimal',
        label: 'Page Views / Article',
        hint: 'Average number of page views per article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleSocialReferralsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.socialReferrals',
        apiName: 'socialReferrals',
        format: 'integer',
        label: 'Social Refs',
        hint: 'Number of social referrals to the article in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleSearchReferralsColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.searchReferrals',
        apiName: 'searchReferrals',
        format: 'integer',
        label: 'Search Refs',
        hint: 'Number of Organic Search referrals to the article in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleCostPerUserColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerUser',
        apiName: 'costPerUser',
        format: 'money',
        label: '$ / User',
        hint: 'Total cost of the article viewed in the selected period divided by the number of users who viewed the article',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleCostPerPageViewColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerPageView',
        apiName: 'costPerPageView',
        format: 'money',
        label: '$ / Page View',
        hint: 'Total cost of the article divided by the number of page views of the article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleCostPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerArticle',
        apiName: 'costPerArticle',
        format: 'money',
        label: 'Cost',
        hint: 'Total cost of the article',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleCostPerSocialReferralColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerSocialReferral',
        apiName: 'costPerSocialReferral',
        format: 'money',
        label: '$ / Social Ref',
        hint: 'Total cost of the article divided by the number of social referrals to the article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleCostPerSearchReferralColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.costPerSearchReferral',
        apiName: 'costPerSearchReferral',
        format: 'money',
        label: '$ / Search Ref',
        hint: 'Total cost of the article divided by the number of search referrals to the article in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'financial'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleByVisitorsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.users',
        apiName: 'visitorsHistory',
        format: 'history',
        label: 'Users Trend',
        hint: 'History of the number of visitors to the article in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleByViewsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.pageViews',
        apiName: 'pageViewsHistory',
        format: 'history',
        label: 'Page Views Trend',
        hint: 'History of the page views on the article in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleBySocialReferralsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.socialReferrals',
        apiName: 'socialReferralsHistory',
        format: 'history',
        label: 'Social Referrals Trend',
        hint: 'History of the social referrals on the article in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleBySearchReferralsHistoryColumn = {
    column: {
        type: 'default_history',
        defaultColumnName: 'default.searchReferrals',
        apiName: 'searchReferralsHistory',
        format: 'history',
        label: 'Search Referrals Trend',
        hint: 'History of the Organic Search referrals on the article in the selected period. Bars are matching your selection in the top chart.',
        sortable: false,
        pushToRight: true,
        groupByOperation: 'sumForEach'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleUsersPerArticleColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.usersPerArticle',
        apiName: 'usersPerArticle',
        format: 'decimal',
        label: 'Users',
        hint: 'Number of uniq users who visited the article in the selected period divided by the number of articles',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleAvgSessionDurationColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.avgSessionDuration',
        apiName: 'avgSessionDuration',
        format: 'time',
        label: 'Avg. Session Duration',
        hint: 'Average duration of a session where article was viewed in the selected period',
        sortable: true,
        groupByOperation: 'avg'
    },
    group: 'general'
};

/**
 * @type {import('smg-iq').ReportColumnWithGroup}
 */
export const ArticleTotalSessionsDurationColumn = {
    column: {
        type: 'default',
        defaultColumnName: 'default.totalSessionsDuration',
        apiName: 'totalSessionsDuration',
        format: 'time',
        label: 'Total Sessions Duration',
        hint: 'Total duration of all sessions where article was viewed in the selected period',
        sortable: true,
        groupByOperation: 'sum'
    },
    group: 'general'
};
