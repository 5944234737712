import { useState, useCallback } from 'react';

const useStateWithCallback = (defaultValue) => {
    const [value, setValue] = useState(defaultValue);
    const handleValue = useCallback((newValue) => {
        setValue(newValue);
    }, []);

    return [value, handleValue];
};

export { useStateWithCallback };
